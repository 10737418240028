import { NavigateFunction } from 'react-router/dist/lib/hooks';
import {
  getNextStep,
  getPreviousStep,
  ProjectStep,
  projectStepsData,
} from 'models/oav/ProjectStep.models';

export const navigateToNextStep = (
  currentStep: ProjectStep,
  projectId: string,
  navigate: NavigateFunction,
) => {
  const nextStep = getNextStep(currentStep);
  if (nextStep)
    navigate(`/projets/${projectId}/${projectStepsData[nextStep]!.path}`);
};

export const navigateToPreviousStep = (
  currentStep: ProjectStep,
  projectId: string,
  navigate: NavigateFunction,
) => {
  const nextStep = getPreviousStep(currentStep);
  if (nextStep)
    navigate(`/projets/${projectId}/${projectStepsData[nextStep]!.path}`);
};
