import React from 'react';
import { Grid } from '@mui/material';
import { FieldReadOnly } from 'components/FieldReadOnly/FieldReadOnly';
import { Person } from 'models/oav/Person.models';

interface ContactInfoViewTabProps {
  person: Person;
}

export const ContactInfoViewTab = (props: ContactInfoViewTabProps) => (
  <>
    <Grid item xs={6}>
      <FieldReadOnly
        label="N° et nom de voie"
        value={props.person.address?.addressLine1}
      />
    </Grid>
    <Grid item xs={6} />
    <Grid item xs={6}>
      <FieldReadOnly
        label="Complément d'adresse 1"
        value={props.person.address?.addressLine2}
      />
    </Grid>
    <Grid item xs={6}>
      <FieldReadOnly
        label="Complément d'adresse 2"
        value={props.person.address?.addressLine3}
      />
    </Grid>
    <Grid item xs={6}>
      <FieldReadOnly
        label="Code postal"
        value={props.person.address?.postalCode}
      />
    </Grid>
    <Grid item xs={6}>
      <FieldReadOnly label="Ville" value={props.person.address?.city} />
    </Grid>
    <Grid item xs={6}>
      <FieldReadOnly label="Téléphone mobile" value={props.person.phone} />
    </Grid>
    <Grid item xs={6}>
      <FieldReadOnly label="Téléphone fixe" value={props.person.landline} />
    </Grid>
    <Grid item xs={6}>
      <FieldReadOnly label="E-mail" value={props.person.email} />
    </Grid>
  </>
);
