import React, { useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Form, Formik, FormikProps } from 'formik';
import { produce } from 'immer';
import * as Yup from 'yup';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import Card from '@mui/material/Card';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useProjectContext } from 'components/Context/ProjectContext';
import { appName } from 'App';
import {
  OfferNeedType,
  OfferNeedWeight,
  offerNeedWeightLabels,
} from 'models/offerNeed.models';
import { FormikObserver } from 'utils/formik/FormikObserver';
import { ToggleButtonGroupSplit } from 'components/ToggleButton/ToggleButtonGroupSplit';
import { ToggleButtonSplit } from 'components/ToggleButton/ToggleButtonSplit';
import {
  updateProjectStepNeeds,
  useProjectStepNeedsMutation,
} from 'api/oav/ProjectStep.api';
import {
  Project,
  ProjectNeeds,
  ProjectStepNeedsRequest,
} from 'models/oav/Project.models';
import { NeedRoutineCareIcon } from 'components/Icon/NeedRoutineCareIcon';
import { NeedOpticalIcon } from 'components/Icon/NeedOpticalIcon';
import { Box, CircularProgress, Skeleton } from '@mui/material';
import { useAppContext } from 'components/Context/AppContext';
import { getDefaultErrorSnackBar } from 'utils/snackbars/Snackbars';
import { NeedSoftMedicineIcon } from 'components/Icon/NeedSoftMedicineIcon';
import {
  getNextStep,
  getPreviousStep,
  ProjectStep,
  projectStepsData,
} from 'models/oav/ProjectStep.models';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { projectQueries } from 'api/oav/ProjectQuery.api';
import { useAccessToken } from 'utils/api/api';
import CardError from 'components/Card/CardError';
import { RestError } from 'errors/RestError';

const OfferNeedToggleButton = (props: { value: OfferNeedWeight }) => {
  return (
    <ToggleButtonSplit
      fullWidth
      value={props.value}
      color="primary"
      aria-label={offerNeedWeightLabels[props.value].label}
      sx={{
        whiteSpace: 'nowrap',
      }}
    >
      {offerNeedWeightLabels[props.value].label}
    </ToggleButtonSplit>
  );
};

type OfferNeedFormValues = {
  offerNeeds: { [key in GroupKey]: OfferNeedWeight | null };
};

enum GroupKey {
  GROUP_1,
  GROUP_2,
  GROUP_3,
}

const groupedNeeds = [
  {
    label: 'Soins courants & hospitalisation',
    icon: NeedRoutineCareIcon,
    needs: [OfferNeedType.SOINS_COURANTS, OfferNeedType.HOSPITALISATION],
    key: GroupKey.GROUP_1,
  },
  {
    label: 'Optique, dentaire & auditif',
    icon: NeedOpticalIcon,
    needs: [
      OfferNeedType.OPTIQUE,
      OfferNeedType.DENTAIRE,
      OfferNeedType.PROTHESE_AUDITIVE,
    ],
    key: GroupKey.GROUP_2,
  },
  {
    label: 'Prévention & médecine douce',
    icon: NeedSoftMedicineIcon,
    needs: [OfferNeedType.CURE_DE_SOIN, OfferNeedType.MEDECINE_DOUCE],
    key: GroupKey.GROUP_3,
  },
];

const formValuesToNeeds = (values: OfferNeedFormValues) => {
  const needs: ProjectNeeds = {};
  Object.entries(values.offerNeeds).forEach(([key, value]) => {
    if (value !== null) {
      groupedNeeds
        .find(_ => _.key.toString() === key)
        ?.needs?.forEach(need => (needs[need] = value));
    }
  });
  return needs;
};

const CURRENT_STEP = ProjectStep.NEEDS;

const ProjectNeedsPage: React.FC = () => {
  document.title = `Projet - ${appName}`;

  const { id } = useParams();
  const navigate = useNavigate();

  if (!id) return <></>;

  const theme = useTheme();
  const screenSizeDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const { addSnackbar } = useAppContext();

  const { setDataCurrent } = useProjectContext();

  const queryClient = useQueryClient();
  const projectQuery = useQuery(projectQueries.getById(id));

  const contextStatus = useMemo<'loading' | 'error' | 'done'>(() => {
    if (projectQuery.isError) return 'error';
    if (projectQuery.isLoading || !projectQuery.data) return 'loading';
    return 'done';
  }, [projectQuery]);

  const accessToken = useAccessToken();
  const mutation = useMutation({
    mutationFn: (payload: ProjectStepNeedsRequest) =>
      updateProjectStepNeeds(accessToken, id, payload),
    onSuccess: (project: Project) => {
      queryClient.setQueryData(['projects', project.id], () => project);
      const currentStep = ProjectStep.NEEDS;
      const nextStep = currentStep && getNextStep(currentStep);
      if (nextStep)
        navigate(`/projets/${id}/${projectStepsData[nextStep]!.path}`);
    },
    onError: (error: Error) => {
      if (error instanceof RestError && error.status === 400) {
        if (refForm.current && error.response.details?.needs) {
          refForm.current.setFieldError(
            'offerNeeds',
            error.response.details.needs,
          );
          refForm.current.setFieldTouched('offerNeeds');
        }
      } else {
        addSnackbar(getDefaultErrorSnackBar('Une erreur est survenue.'));
      }
    },
  });

  const refForm = useRef<FormikProps<OfferNeedFormValues>>(null);

  const getInitialValues = (): OfferNeedFormValues => {
    const values: OfferNeedFormValues = {
      offerNeeds: {
        [GroupKey.GROUP_1]: null,
        [GroupKey.GROUP_2]: null,
        [GroupKey.GROUP_3]: null,
      },
    };

    if (!projectQuery.data) return values;

    groupedNeeds.forEach(groups => {
      const type = groups.needs.find(
        need =>
          (projectQuery.data.needs && projectQuery.data.needs[need]) !==
          undefined,
      );
      if (type) {
        values.offerNeeds[groups.key] = projectQuery.data.needs![type]!;
      }
    });

    return values;
  };

  const validationSchema = Yup.object({
    offerNeeds: Yup.object().test({
      test: value => Object.values(value).find(v => v === null) !== null,
      message: 'Vous devez sélectionner tous les besoins.',
    }),
  });

  const onChange = (values: OfferNeedFormValues) => {
    setDataCurrent(
      produce(state => {
        if (!state.project || !state.project.subscriptions) {
          return state;
        }

        state.project.needs = formValuesToNeeds(values);
      }),
    );
  };

  const onSubmit = (values: OfferNeedFormValues) => {
    mutation.mutate({
      needs: formValuesToNeeds(values),
    });
  };

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <Stack direction="row">
          <Typography variant="h3" flexGrow={1}>
            Besoins
          </Typography>
        </Stack>
      </Grid>

      {contextStatus === 'done' && projectQuery.data ? (
        <Grid item xs={12}>
          <Formik
            innerRef={refForm}
            initialValues={getInitialValues()}
            onSubmit={values => {
              return onSubmit(values);
            }}
            validationSchema={validationSchema}
          >
            {({ handleSubmit, setFieldValue, values, errors, touched }) => {
              return (
                <Form>
                  <FormikObserver onChange={onChange} />
                  <Grid
                    container
                    justifyContent="center"
                    spacing={{ sm: 2, xs: 1 }}
                  >
                    <Grid item xs={12}>
                      <Stack
                        direction="column"
                        justifyContent="space-between"
                        alignItems="stretch"
                        spacing={2}
                      >
                        {groupedNeeds.map(_ => (
                          <Card
                            key={_.key}
                            sx={{
                              height: '100%',
                              p: 2,
                            }}
                          >
                            <Stack justifyContent="space-between" gap={1}>
                              <Stack direction="row" spacing={1}>
                                <Box>
                                  {React.createElement(_.icon, {
                                    size: 'medium',
                                  })}
                                </Box>
                                <Typography>{_.label}</Typography>
                              </Stack>
                              <ToggleButtonGroupSplit
                                orientation={
                                  screenSizeDownSM ? 'vertical' : 'horizontal'
                                }
                                value={values.offerNeeds[_.key]}
                                sx={{
                                  width: '100%',
                                }}
                                style={{ marginLeft: 0 }}
                                exclusive
                                onChange={(e, value) =>
                                  setFieldValue('offerNeeds', {
                                    ...values.offerNeeds,
                                    [_.key]: value,
                                  })
                                }
                              >
                                <OfferNeedToggleButton
                                  value={OfferNeedWeight.PAS_DE_BESOIN}
                                />
                                <OfferNeedToggleButton
                                  value={OfferNeedWeight.BASIQUE}
                                />
                                <OfferNeedToggleButton
                                  value={OfferNeedWeight.EQUILIBRE}
                                />
                                <OfferNeedToggleButton
                                  value={OfferNeedWeight.CONFORTABLE}
                                />
                              </ToggleButtonGroupSplit>
                            </Stack>
                          </Card>
                        ))}
                      </Stack>
                    </Grid>

                    {errors.offerNeeds && touched.offerNeeds && (
                      <Grid item xs={12}>
                        <FormHelperText error>
                          {errors.offerNeeds as unknown as string}
                        </FormHelperText>
                      </Grid>
                    )}

                    <Grid item sm="auto" xs={12}>
                      <Button
                        fullWidth
                        color="default"
                        disabled={mutation.isPending}
                        onClick={() => {
                          const prevStep = getPreviousStep(CURRENT_STEP);
                          if (prevStep)
                            navigate(
                              `/projets/${id}/${projectStepsData[prevStep]!.path}`,
                            );
                        }}
                        sx={{ px: 4 }}
                      >
                        Retour
                      </Button>
                    </Grid>

                    <Grid item sm="auto" xs={12}>
                      <Button
                        fullWidth
                        disabled={mutation.isPending}
                        onClick={() => handleSubmit()}
                        sx={{ px: 4 }}
                      >
                        {mutation.isPending ? (
                          <CircularProgress color="inherit" size={28} />
                        ) : (
                          'Valider'
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      ) : contextStatus === 'error' ? (
        <Grid
          item
          xs={12}
          sx={{
            height: 200,
          }}
        >
          <CardError
            status={
              projectQuery.error instanceof RestError
                ? projectQuery.error.status
                : undefined
            }
          />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Skeleton variant="rectangular" animation="wave" height="200px" />
        </Grid>
      )}
    </Grid>
  );
};

export default ProjectNeedsPage;
