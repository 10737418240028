import React from 'react';
import { useUserContext } from 'components/Context/UserContext';
import { Grid } from '@mui/material';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { FieldReadOnly } from 'components/FieldReadOnly/FieldReadOnly';

const AccountMePage: React.FC = () => {
  const user = useUserContext();
  const theme = useTheme();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ width: '100%', p: 4, my: 2, border: 1 }}>
            <Grid
              container
              direction={{ xs: 'column', md: 'row' }}
              alignItems="center"
            >
              <Grid item xs={2}>
                <Avatar
                  alt={user.data.user?.name}
                  src={user.data.user?.pictureUri}
                  sx={{
                    border: 0,
                    borderColor: theme.palette.primary.main,
                    width: 128,
                    height: 128,
                  }}
                />
              </Grid>
              <Grid item xs={10} p={2}>
                <Typography variant="h3" sx={{ py: 2 }}>
                  {user.data.user?.name}
                </Typography>
                <Typography>{user.data.agency?.companyName}</Typography>
                <Typography>
                  Apporteur n°{user.data.user?.codeApporteur}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <FieldReadOnly label={'Titre'} value={user.data.user?.title} />
        </Grid>
        <Grid item xs={12}>
          <FieldReadOnly
            label={'Email de contact'}
            value={user.data.user?.email}
          />
        </Grid>
        <Grid item xs={12}>
          <FieldReadOnly
            label={'Téléphone de contact'}
            value={user.data.user?.phone_number}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AccountMePage;
