import { Segment } from 'models/referentiels/Segment';
import { WrongStatusError } from './index';

const url = `${process.env.REACT_APP_API_URI_BASE}/referentiels/segment`;

const fetchSegments = async (accessToken: string): Promise<Segment[]> => {
  const response = await fetch(
    url +
      '?' +
      new URLSearchParams({
        sort: '+label',
      }),
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
  if (response.status !== 200) {
    throw new WrongStatusError(response.status, 'Wrong http status.');
  }
  return await response.json();
};

export { fetchSegments };
