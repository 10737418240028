import { Address } from './Address.models';

export interface Person {
  id?: string;
  civility?: Civility;
  gender?: Gender;
  title?: string;
  lastname?: string;
  firstname?: string;
  birthdate?: Date;
  email?: string;
  phone?: string;
  landline?: string;
  regimeCode?: string;
  nni?: string;
  birthOrder?: number;
  cpamId?: string;
  address?: Address;
}

export type PersonRequest = Omit<Person, 'id'>;

export type PersonContactRequest = {
  civility?: Civility;
  lastname: string;
  firstname: string;
  phone?: string;
  landline?: string;
  email?: string;
};

export type PersonRequestPostErrors = {
  civility?: string;
  gender?: string;
  title?: string;
  lastname?: string;
  firstname?: string;
  birthdate?: string;
  email?: string;
  phone?: string;
  landline?: string;
  regimeCode?: string;
  nni?: string;
  birthOrder?: string;
  cpamId?: string;
};

export interface PersonSearchCriteriaRequest {
  birthdate: Date | null;
  lastname: string;
  firstname: string;
  email: string;
}

export enum Civility {
  'M' = 'M',
  'Mme' = 'Mme',
}

export const civilityLabels: { [key in Civility]: { label: string } } = {
  [Civility.M]: { label: 'Monsieur' },
  [Civility.Mme]: { label: 'Madame' },
};

export enum Gender {
  'MAN' = 'MAN',
  'WOMAN' = 'WOMAN',
}

export const genderLabels: { [key in Gender]: { label: string } } = {
  [Gender.MAN]: { label: 'Homme' },
  [Gender.WOMAN]: { label: 'Femme' },
};
