import { IconProps } from './settings';
import { Icon } from './index';

export const NeedDentalIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3.03823 5.96716C-2.73098 11.5498 4.77199 34.4117 12.7788 36.8136C13.297 36.969 13.7823 36.5755 13.8723 36.0421C14.3194 33.3909 15.8913 26.1511 19.7252 26.1511C23.5643 26.1511 24.612 33.4106 24.8661 36.0529C24.9172 36.5841 25.3619 37.0042 25.8649 36.8261C32.1398 34.6032 43.3941 11.7264 37.1944 5.96716C30.676 -0.0880313 24.1577 4.958 20.2467 5.2103C16.3357 5.4626 9.03512 0.16417 3.03823 5.96716Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </Icon>
  );
};
