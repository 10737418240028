import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchRisks, fetchSegments } from 'api/referentiels';
import { useAuth } from 'react-oidc-context';
import { fetchRegimes } from 'api/referentiels/Regime.api';
import {
  Person,
  PersonRequest,
  PersonSearchCriteriaRequest,
} from 'models/oav/Person.models';
import {
  fetchPerson,
  fetchPersonList,
  postPerson,
  putPerson,
} from 'api/oav/Person.api';
import { Pagination } from 'models/Page.models';

const useAccessToken = (): string => {
  const auth = useAuth();
  const accessToken = auth.user?.access_token;

  if (!accessToken) {
    throw new Error(
      'Fetch could not be performed, secured endpoint requested without being authenticated.',
    );
  }
  return accessToken;
};

export const useSegments = () => {
  const accessToken = useAccessToken();
  return useQuery({
    queryKey: ['segments'],
    queryFn: () => fetchSegments(accessToken),
  });
};

export const useRisks = () => {
  const accessToken = useAccessToken();
  return useQuery({
    queryKey: ['risks'],
    queryFn: () => fetchRisks(accessToken),
  });
};

export const useRegimes = () => {
  const accessToken = useAccessToken();
  return useQuery({
    queryKey: ['regimes'],
    queryFn: () => fetchRegimes(accessToken),
    staleTime: 300000, // 5min
    gcTime: 300000, // 5min
  });
};

export const usePutPersonMutation = (
  onSuccess?: (person: Person) => void,
  onError?: (error: Error) => void,
) => {
  const accessToken = useAccessToken();
  const client = useQueryClient();
  return useMutation({
    mutationFn: (params: { person: PersonRequest; uuid: string }) =>
      putPerson(params.person, params.uuid, accessToken),
    onSuccess: (person: Person) => {
      // Save query data in order to avoid further fetch on this person
      client.setQueryData(['person', person.id], () => person);
      client
        .resetQueries({ queryKey: ['person', 'list'] })
        .then()
        .catch();
      onSuccess?.(person);
    },
    onError: (error: Error) => {
      onError?.(error);
    },
  });
};

export const usePostPersonMutation = (
  onSuccess?: (person: Person) => void,
  onError?: (error: Error) => void,
) => {
  const accessToken = useAccessToken();
  const client = useQueryClient();
  return useMutation({
    mutationFn: (person: PersonRequest) => postPerson(person, accessToken),
    onSuccess: (person: Person) => {
      // Save query data in order to avoid further fetch on this person
      client.setQueryData(['person', person.id], () => person);
      client
        .resetQueries({ queryKey: ['person', 'list'] })
        .then()
        .catch();
      onSuccess?.(person);
    },
    onError: (error: Error) => {
      onError?.(error);
    },
  });
};

export const usePerson = (uuid: string) => {
  const accessToken = useAccessToken();
  return useQuery({
    queryKey: ['person', uuid],
    queryFn: () => fetchPerson(uuid, accessToken),
    gcTime: 300000, // 5min
    staleTime: 300000, // 5min
  });
};

export const usePersonList = (
  pagination: Pagination,
  criteria: PersonSearchCriteriaRequest,
) => {
  const accessToken = useAccessToken();
  return useQuery({
    queryKey: ['person', 'list', pagination.page, pagination.size, criteria],
    queryFn: () => fetchPersonList(pagination, criteria, accessToken),
    gcTime: 300000, // 5min
    staleTime: 300000, // 5min
  });
};
