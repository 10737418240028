import * as Yup from 'yup';

export const addressLineMaxChar = 300;
export const addressLineMinChar = 5;

export const cityMaxChar = 255;
export const cityMinChar = 2;

export const yupAddressLine1 = Yup.string()
  .min(
    addressLineMinChar,
    `Adresse non valide (ne peut pas être inférieur à ${addressLineMinChar} caractères)`,
  )
  .max(
    addressLineMaxChar,
    `Adresse non valide (ne peut pas être supérieur à ${addressLineMaxChar} caractères)`,
  );

export const yupAddressLine2 = Yup.string().max(
  addressLineMaxChar,
  `Complément d'adresse non valide (ne peut pas être supérieur à ${addressLineMaxChar} caractères)`,
);

export const yupAddressLine3 = yupAddressLine2;

export const yupCity = Yup.string()
  .min(
    cityMinChar,
    `Ville non valide (ne peut pas être inférieur à ${cityMinChar} caractères)`,
  )
  .max(
    cityMaxChar,
    `Ville non valide (ne peut pas être supérieur à ${cityMaxChar} caractères)`,
  );
