import {
  WarrantyAcronym,
  WarrantyAcronymMap,
  WarrantyAmount,
  WarrantySubSection,
} from 'models/referentiels/Warranty.model';
import { Formula } from 'models/referentiels/Formula.model';
import React, { Fragment, memo, useMemo } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { alpha, useTheme } from '@mui/material/styles';
import WarrantyFormulaAmounts from 'content/warranty/WarrantyFormulaAmounts';
import { Theme } from '@mui/material/styles/createTheme';

export type WarrantySubSectionTableRowsProps = {
  subSection: WarrantySubSection;
  nbFormula: number;
  formulas: Formula[];
  formulasSelected?: string[];
  acronymsMap?: WarrantyAcronymMap;
};

export const WarrantySubSectionTableRows = (
  props: WarrantySubSectionTableRowsProps,
) => {
  const theme = useTheme();

  const warranties = useMemo(() => {
    return props.subSection.warranties.sort((a, b) => a.order - b.order);
  }, [props.subSection]);

  return (
    <>
      <TableRow>
        <TableCell
          align={props.nbFormula === 1 ? 'right' : 'left'}
          colSpan={props.nbFormula}
          sx={{
            pt: 2,
          }}
        >
          <Typography>{props.subSection.subSectionTitle}</Typography>
        </TableCell>
      </TableRow>

      {warranties.map((warranty, warrantyIdx) => (
        <Fragment key={warrantyIdx}>
          <TableRow>
            <TableCell
              align={props.nbFormula === 1 ? 'right' : 'left'}
              colSpan={props.nbFormula}
              sx={{
                py: 0.5,
                pl: 1,
              }}
            >
              <Typography variant="body2" fontSize="0.9rem">
                {warranty.label}
              </Typography>
            </TableCell>
          </TableRow>

          <TableRow>
            {props.formulas.map((f, idx) => {
              const selected =
                props.formulasSelected &&
                props.formulasSelected.includes(f.code);

              const formulaWarranty = warranty.formulas.find(
                fw => fw.formulaCode === f.code,
              );

              return (
                <WarrantyTableCell
                  key={f.code}
                  amounts={formulaWarranty?.amounts}
                  acronymsMap={props.acronymsMap}
                  idx={idx}
                  nbFormula={props.nbFormula}
                  selected={!!selected}
                  theme={theme}
                />
              );
            })}
          </TableRow>
        </Fragment>
      ))}
    </>
  );
};

const WarrantyTableCell = memo(
  ({
    idx,
    theme,
    selected,
    amounts,
    acronymsMap,
    nbFormula,
  }: {
    idx: number;
    theme: Theme;
    selected: boolean;
    amounts: WarrantyAmount[] | undefined;
    acronymsMap: Record<string, WarrantyAcronym> | undefined;
    nbFormula: number;
  }) => (
    <TableCell
      align={nbFormula === 1 ? 'right' : 'center'}
      sx={{
        py: 1,
        maxWidth: '1px',
        overflow: 'hidden',
        width: `calc(100% / ${nbFormula})`,
        borderLeft: idx === 0 ? 0 : 2,
        borderRight: idx + 1 === nbFormula ? 0 : 2,
        borderBottom: nbFormula === 1 ? 1 : 0,
        borderColor: theme.palette.divider,
        background: selected ? alpha(theme.palette.primary.main, 0.1) : 'none',
      }}
    >
      <Typography
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <WarrantyFormulaAmounts
          formulaWarrantyAmounts={amounts}
          acronymsMap={acronymsMap}
        />
      </Typography>
    </TableCell>
  ),
);
WarrantyTableCell.displayName = 'WarrantyTableCell';
