import { ToggleButtonGroup, ToggleButtonGroupProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const ToggleButtonGroupSplit = (props: ToggleButtonGroupProps) => {
  const theme = useTheme();
  return (
    <ToggleButtonGroup
      {...props}
      sx={{
        ...props.sx,
        gap: 2,
        '.MuiToggleButtonGroup-grouped': {
          border: 2,
          color: theme.palette.text.primary,
          transition:
            'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        },
        '.MuiToggleButtonGroup-middleButton, .MuiToggleButtonGroup-lastButton, .MuiToggleButtonGroup-firstButton':
          {
            borderRadius: 32,
          },
      }}
    />
  );
};
