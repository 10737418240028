import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Divider } from '@mui/material';
import React, { ReactElement } from 'react';

export type ItemProps = {
  label: string;
  value?: string | ReactElement;
};

export const FieldReadOnly = (props: ItemProps) => {
  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      alignItems={'stretch'}
      sx={{
        minHeight: '5rem',
      }}
    >
      <Typography variant="body2">{props.label}</Typography>
      <Typography variant="body1">{props.value} </Typography>
      <Divider sx={{ my: 2 }} />
    </Stack>
  );
};
