import { useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { appName } from 'App';
import { useProjectContext } from 'components/Context/ProjectContext';
import FormOfferSelect, {
  FormOfferSelectRef,
  FormOfferSelectValues,
} from 'content/form/FormOfferSelect';
import { CircularProgress, Skeleton } from '@mui/material';
import {
  getNextStep,
  getStepFromPath,
  projectStepsData,
} from 'models/oav/ProjectStep.models';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { projectQueries } from 'api/oav/ProjectQuery.api';
import CardError from 'components/Card/CardError';
import { RestError } from 'errors/RestError';

const ProjectOffersPage: React.FC = () => {
  document.title = `Projet - ${appName}`;

  const navigate = useNavigate();

  const { id } = useParams();
  const { pathname } = useLocation();
  const refForm = useRef<FormOfferSelectRef>(null);

  if (!id) return <></>;

  const contextProject = useProjectContext();

  const queryClient = useQueryClient();
  const projectQuery = useQuery(projectQueries.getById(id));
  const offersQuery = useQuery(projectQueries.getByIdOffers(id));

  const contextStatus = useMemo<'loading' | 'error' | 'done'>(() => {
    if (projectQuery.isError || offersQuery.isError) return 'error';
    if (
      projectQuery.isLoading ||
      offersQuery.isLoading ||
      !projectQuery.data ||
      !offersQuery.data
    )
      return 'loading';
    return 'done';
  }, [projectQuery, offersQuery]);

  const [loading, setLoading] = useState<boolean>(false);

  const getInitialValues = (): FormOfferSelectValues => {
    return {
      subscriptions: projectQuery.data?.subscriptions,
    };
  };

  const submit = async () => {
    setLoading(true);

    refForm.current
      ?.submitForm()
      .then(project => {
        if (!project) return;

        contextProject.resetSelectedFormulas();
        // TODO : mutate.
        queryClient.setQueryData(['projects', id], () => project);

        const currentStep = getStepFromPath(pathname);
        const nextStep = currentStep && getNextStep(currentStep);
        if (nextStep)
          navigate(`/projets/${id}/${projectStepsData[nextStep]!.path}`);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h3" flexGrow={1}>
          Offres
          <Chip
            sx={{
              ml: 1,
            }}
            label={
              contextProject.dataCurrent?.project?.subscriptions?.length || 0
            }
          />
        </Typography>
      </Grid>

      {contextStatus === 'done' && projectQuery.data && offersQuery.data ? (
        <>
          <Grid item xs={12}>
            <FormOfferSelect
              ref={refForm}
              project={projectQuery.data}
              options={offersQuery.data}
              initialValues={getInitialValues()}
              onChangeValues={_ => {
                contextProject.setDataCurrent(prev => {
                  if (!prev.project) return prev;
                  return {
                    ...prev,
                    project: {
                      ...prev.project,
                      subscriptions: _.subscriptions,
                    },
                  };
                });
              }}
              maxOffers={1}
            />
          </Grid>
          <Grid item sm="auto" xs={12}>
            <Button
              fullWidth
              disabled={loading}
              onClick={() => {
                submit();
              }}
              sx={{ px: 4 }}
            >
              {loading ? (
                <CircularProgress color="inherit" size={28} />
              ) : (
                'Valider'
              )}
            </Button>
          </Grid>
        </>
      ) : contextStatus === 'error' ? (
        <Grid
          item
          xs={12}
          sx={{
            height: 200,
          }}
        >
          <CardError
            status={
              projectQuery.error instanceof RestError
                ? projectQuery.error.status
                : undefined
            }
          />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Skeleton variant="rectangular" animation="wave" height="200px" />
        </Grid>
      )}
    </Grid>
  );
};

export default ProjectOffersPage;
