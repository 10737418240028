import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { PersonCircleIcon } from '../../components/Icon/PersonCircleIcon';
import { PersonDuoCircleIcon } from '../../components/Icon/PersonDuoCircleIcon';
import { PersonTrioCircleIcon } from '../../components/Icon/PersonTrioCircleIcon';
import { BeneficiaryType } from 'models/oav/Beneficiary.models';

export type FormBeneficiaryExpressCardProps = {
  type: BeneficiaryType;
  count?: number;
  selected?: boolean;
  onClick?: (_: boolean) => void;
  children: JSX.Element;
};

const FormBeneficiaryExpressCard = (props: FormBeneficiaryExpressCardProps) => {
  const theme = useTheme();

  const IconComponent =
    props.type === BeneficiaryType.SUBSCRIBER
      ? PersonCircleIcon
      : props.type === BeneficiaryType.PARTNER
        ? PersonDuoCircleIcon
        : PersonTrioCircleIcon;

  const label: string =
    props.type === BeneficiaryType.SUBSCRIBER
      ? 'Votre client(e)'
      : props.type === BeneficiaryType.PARTNER
        ? 'Son conjoint'
        : 'Ses enfants';

  return (
    <Card
      sx={{
        cursor:
          props.onClick === undefined || props.selected ? 'default' : 'pointer',
        borderRadius: 6,
        p: {
          lg: 3,
          xs: 2,
        },
        transition: 'transform 150ms ease-in-out',
        ':hover': {
          transform:
            props.onClick === undefined || props.selected
              ? 'none'
              : 'translateY(-6px)',
        },
      }}
      onClick={() => {
        if (props.selected || props.onClick === undefined) return;
        props.onClick(true);
      }}
    >
      <Grid
        container
        spacing={{
          sm: 4,
          xs: props.selected ? 2 : 0,
        }}
      >
        <Grid
          item
          sm="auto"
          xs={12}
          sx={{
            minWidth: {
              sm: 240,
              xs: 'auto',
            },
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            onClick={() => {
              if (props.onClick === undefined) return;
              props.onClick(!props.selected);
            }}
            sx={{
              cursor: props.onClick === undefined ? 'default' : 'pointer',
            }}
          >
            {(props.onClick || props.selected !== undefined) && (
              <Checkbox checked={props.selected} />
            )}
            <IconComponent
              size="large"
              color={theme.palette.background.default}
              bgColor={theme.palette.text.primary}
            />
            <Typography component="span">{label}</Typography>
            {props.selected && props.count !== undefined && (
              <Chip label={props!.count.toString()} />
            )}
          </Stack>
        </Grid>

        <Grid
          item
          xs
          sx={{
            transition:
              'max-height 150ms ease-in-out, visibility 150ms ease-in-out',
            maxHeight: props.selected === false ? '0px' : '1000px',
            visibility: props.selected === false ? 'hidden' : 'visible',
          }}
        >
          {/* Reset form  */}
          {/* {selected && props.children} */}

          {/* Keep form values */}
          {props.children}
        </Grid>
      </Grid>
    </Card>
  );
};

export default FormBeneficiaryExpressCard;
