import * as Yup from 'yup';
import {
  getYupBirthDate,
  yupBirthOrder,
  yupCpamId,
  yupEmail,
  yupFieldFirstName,
  yupFieldLastName,
  yupFieldPostalCode,
  yupNni,
} from 'utils/validation/yupPerson';
import { messageFieldRequired } from 'utils/validation/message';
import { PersonFormValues } from 'models/Form.model';
import { Person, PersonRequest } from 'models/oav/Person.models';

export const personValidationSchema = Yup.object().shape({
  birthdate: getYupBirthDate().required(messageFieldRequired),
  firstname: yupFieldFirstName.required(messageFieldRequired),
  lastname: yupFieldLastName.required(messageFieldRequired),
  nni: yupNni,
  cpamId: yupCpamId,
  civility: Yup.string().required(messageFieldRequired),
  birthOrder: yupBirthOrder,
  postalCode: yupFieldPostalCode,
  email: yupEmail,
});

export const personFormValuesToRequest = (
  values: PersonFormValues,
): PersonRequest => {
  let address: Person['address'] | undefined = undefined;

  if (values.city && values.addressLine1 && values.postalCode) {
    address = {
      city: values.city,
      addressLine1: values.addressLine1,
      addressLine2: values.addressLine2 || undefined,
      addressLine3: values.addressLine3 || undefined,
      postalCode: values.postalCode,
    };
  }
  return {
    firstname: values.firstname,
    lastname: values.lastname,
    phone: values.phone || undefined,
    landline: values.landline || undefined,
    email: values.email || undefined,
    nni: values.nni || undefined,
    cpamId: values.cpamId || undefined,
    civility: values.civility!,
    gender: values.gender || undefined,
    birthdate: values.birthdate!,
    regimeCode: values.regime || undefined,
    birthOrder: values.birthOrder ? Number(values.birthOrder) : undefined,
    address,
  };
};

export const personToInitialFormValues = (person: Person): PersonFormValues => {
  return {
    addressLine1: person.address?.addressLine1 || '',
    addressLine2: person.address?.addressLine2 || '',
    addressLine3: person.address?.addressLine3 || '',
    postalCode: person.address?.postalCode || '',
    city: person.address?.city || '',
    country: person.address?.country || '',
    birthOrder: person.birthOrder?.toString() || '',
    regime: person.regimeCode || '',
    civility: person.civility || null,
    nni: person.nni || '',
    cpamId: person.cpamId || '',
    email: person.email || '',
    birthdate: person.birthdate || null,
    firstname: person.firstname || '',
    gender: person.gender || null,
    landline: person.landline || '',
    lastname: person.lastname || '',
    phone: person.phone || '',
  };
};
