import React, { ReactElement } from 'react';

import { TableCell, TableRow } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

interface TableRowsSkeletonProps {
  rowSize: number;
  cellSize: number;
}

export const LoadingTableRows = (props: TableRowsSkeletonProps) => {
  const rows = [];

  const Cell = () => (
    <TableCell>
      <Skeleton
        variant="rectangular"
        animation="wave"
        height="24px"
        sx={{ maxWidth: '50%' }}
      />
    </TableCell>
  );

  const Cells = () => {
    const cells: ReactElement[] = [];
    for (let i = 0; i < props.cellSize; i++) {
      cells.push(<Cell key={i} />);
    }
    return <>{cells}</>;
  };

  for (let i = 0; i < props.rowSize; i++) {
    rows.push(
      <TableRow key={i}>
        <Cells />
      </TableRow>,
    );
  }

  return <>{rows}</>;
};
