import { Icon } from '.';
import { IconProps } from './settings';

export const NeedCureIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6 25.8373C6 24.5702 6.44876 22.893 7.25984 20.9363C8.06247 19 9.18276 16.8765 10.4425 14.7444C12.9612 10.4812 15.9924 6.25838 18.024 3.55098C18.7735 2.55215 20.2265 2.55215 20.976 3.55098C23.0076 6.25838 26.0388 10.4812 28.5575 14.7444C29.8172 16.8765 30.9375 19 31.7402 20.9363C32.5512 22.893 33 24.5702 33 25.8373C33 33.0848 26.9782 39 19.5 39C12.0218 39 6 33.0848 6 25.8373Z"
          stroke="currentColor"
          strokeWidth="2"
        />
      </svg>
    </Icon>
  );
};
