import Typography from '@mui/material/Typography';

import AppBar from 'components/AppBar';
import { useUserContext } from 'components/Context/UserContext';
import { appName } from 'App';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import React from 'react';
import Stack from '@mui/material/Stack';
import { ButtonNewProject } from 'components/Button/ButtonNewProject/ButtonNewProject';

const DashboardPage: React.FC = () => {
  document.title = `Tableau de bord - ${appName}`;

  const userContext = useUserContext();

  return (
    <>
      <AppBar>
        <Stack
          direction="row"
          justifyContent="space-between"
          flexWrap="wrap"
          useFlexGap
          spacing={1}
          sx={{
            width: '100%',
          }}
        >
          <Typography variant="h2">
            Bienvenue {userContext.data.user?.given_name} !
          </Typography>
          <Stack
            direction="row"
            alignItems="flex-start"
            flexWrap="wrap"
            useFlexGap
            spacing={2}
          >
            <ButtonNewProject />
            <Link to="/personnes/nouveau">
              <Button size="medium" variant="gradient">
                Nouvelle personne
              </Button>
            </Link>
          </Stack>
        </Stack>
      </AppBar>
    </>
  );
};

export default DashboardPage;
