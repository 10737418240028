export class RestError<Response = void> extends Error {
  status: number;
  url: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  request?: any;
  response?: Response;

  constructor(
    status: number,
    url: string,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    request?: any,
    response?: Response,
  ) {
    super();
    this.status = status;
    this.url = url;
    this.method = method;
    this.request = request;
    this.response = response;
  }
}
