import { buildParams } from 'utils/api/params';
import { Regime, RegimeFilters } from 'models/referentiels/Regime.models';
import { RestError } from 'errors/RestError';

const baseUrl = `${process.env.REACT_APP_API_URI_BASE}/referentiels/regime`;

export const fetchRegimes = async (
  accessToken: string,
  filters?: RegimeFilters,
  sort?: string[],
): Promise<Regime[]> => {
  const params = new URLSearchParams();
  if (filters)
    buildParams(filters, 'filters')?.forEach(([key, value]) =>
      params.append(key, value),
    );
  if (sort) params.append('sort', sort.join(','));

  const url: string = params.size > 0 ? `${baseUrl}?${params}` : baseUrl;

  const response = await fetch(url + '?' + params, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'GET',
      undefined,
      await response.json(),
    );

  return response.json();
};

export const fetchRegimeByCode = async (
  accessToken: string,
  code: string,
): Promise<Regime> => {
  const url = `${baseUrl}/${code}`;

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'GET',
      undefined,
      await response.json(),
    );

  return await response.json();
};
