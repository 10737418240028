import TextField from '../TextField';
import React, {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { TextFieldProps } from '@mui/material/TextField';

type FormattedTextField = TextFieldProps & {
  groupsRegex: RegExp;
  removeRegex: RegExp;
  separator: string;
};

const useForceUpdate = () => {
  const [, setState] = useState({});
  return useCallback(() => setState({}), []);
};

export const FormattedTextField = (props: FormattedTextField) => {
  const { separator, groupsRegex, removeRegex, ...textFieldProps } = props;
  const value = props.value as string;
  const forceUpdate = useForceUpdate();

  const formattedValue = useMemo(() => {
    const stringValue = value || '';
    const groups = groupsRegex.exec(stringValue);
    return (
      groups
        ?.reduce((formatted, value, index) => {
          if (index === 0) {
            return '';
          }
          if (value === '') {
            return formatted;
          }
          return `${formatted}${separator}${value}`;
        }, '')
        .trim() || ''
    );
  }, [value, separator, groupsRegex]);

  const caretInfoRef = useRef<{
    onChangeEvent: {
      positionStart: number;
      targetValueLength: number;
    } | null;
    previousFormattedValueLength: number;
  }>({
    onChangeEvent: null,
    previousFormattedValueLength: formattedValue.length,
  });

  const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
    const rawValue = e.target.value
      .replaceAll(separator, '')
      .replaceAll(removeRegex, '');

    if (e.target.selectionStart !== null) {
      caretInfoRef.current.onChangeEvent = {
        positionStart: e.target.selectionStart,
        targetValueLength: e.target.value.length,
      };
    }

    if (rawValue !== props.value) {
      e.target.value = rawValue;
      props.onChange?.(e);
    } else {
      // No change on the value, trigger a render manually to set the correct caret position
      forceUpdate();
    }
  };

  const ref = useRef<HTMLInputElement>();

  useEffect(() => {
    if (!ref.current || !caretInfoRef.current.onChangeEvent) {
      return;
    }

    let positionStart = caretInfoRef.current.onChangeEvent.positionStart;

    const targetValueLength =
      caretInfoRef.current.onChangeEvent.targetValueLength;

    const endsBySeparator = formattedValue
      .slice(0, positionStart)
      .endsWith(props.separator);

    if (endsBySeparator) {
      positionStart =
        targetValueLength < caretInfoRef.current.previousFormattedValueLength
          ? positionStart - 1
          : positionStart + 1;
    }

    ref.current.selectionStart = positionStart;
    ref.current.selectionEnd = positionStart;

    caretInfoRef.current.previousFormattedValueLength = formattedValue.length;
    caretInfoRef.current.onChangeEvent = null;
  });

  return (
    <TextField
      {...textFieldProps}
      value={formattedValue}
      onChange={handleChange}
      inputRef={ref}
    />
  );
};
