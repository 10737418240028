import { FormikErrors, FormikTouched, getIn } from 'formik';
import { useRegimes } from 'queries';
import { Regime } from 'models/referentiels/Regime.models';

export const nniRegex =
  /([0-9])([0-9]{0,2})([0-9]{0,2})([0-9]{0,2})([0-9]{0,3})([0-9]{0,3})([0-9]{0,2})/;

export const formatNni = (value?: string) => {
  const groups = nniRegex.exec(value || '');

  return `${groups?.[1] || ''} ${groups?.[2] || ''} ${groups?.[3] || ''} ${groups?.[4] || ''} ${groups?.[5] || ''} ${groups?.[6] || ''} ${groups?.[7] || ''}`.trim();
};

export const emptyStringToUndefined = <Type extends object>(
  values: Type,
): Type => {
  Object.keys(values).forEach(key => {
    const keyWithType = key as keyof typeof values;
    const value = values[keyWithType];
    if (typeof value === 'string' && value === '') {
      values = { ...values, [keyWithType]: undefined };
    }
  });
  return values;
};

export const getErrorField = <T>(
  errors: FormikErrors<T>,
  touched: FormikTouched<T>,
  field: string,
): boolean => getIn(touched, field) && Boolean(getIn(errors, field));

export const getHelperTextField = <T>(
  errors: FormikErrors<T>,
  touched: FormikTouched<T>,
  field: string,
): string =>
  getIn(touched, field) &&
  Boolean(getIn(errors, field)) &&
  getIn(errors, field);

export const getRegime = (regimeCode?: string): Regime | undefined => {
  const { data: regimes } = useRegimes();

  return regimeCode ? regimes?.find(r => r.code === regimeCode) : undefined;
};
