import { ReactElement, Ref, forwardRef } from 'react';

import Slide from '@mui/material/Slide';

import { TransitionProps } from '@mui/material/transitions';

export const TransitionSlideUP = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement },
  ref: Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
