import React, {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { Formik, Form, FormikProps, FormikHelpers, FormikErrors } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';

import Grid from '@mui/material/Grid';

import { messageFieldRequired } from 'utils/validation/message';
import {
  ageAdultMax,
  ageAdultMin,
  ageChildMax,
  ageChildMin,
  getDateFromAge,
  getYupFieldBirthDateAdult,
  getYupFieldBirthDateChild,
  yupFieldPostalCode,
} from 'utils/validation/yupPerson';
import DatePicker from 'components/DatePicker/DatePicker';
import TextField from 'components/TextField/TextField';
import {
  BeneficiaryRequestPost,
  BeneficiaryRequestPostErrors,
  BeneficiaryType,
} from 'models/oav/Beneficiary.models';
import { RegimeSelect } from 'components/Select/RegimeSelect';
import { FormikObserver } from '../../utils/formik/FormikObserver';

export interface FormBeneficiaryExpressValues {
  id?: string;
  type: BeneficiaryType;
  postalCode?: string;
  birthDate?: Date | null;
  regime?: string;
}

export interface FormBeneficiaryExpressErrors {
  postalCode?: string;
  birthDate?: string;
  regime?: string;
}

export interface FormBeneficiaryExpressRef {
  resetForm: () => void;
  validateForm: () => Promise<any>;
  values: FormBeneficiaryExpressValues;
}

export interface FormBeneficiaryExpressProps {
  type: BeneficiaryType;
  initialValues?: FormBeneficiaryExpressValues;
  errors?: FormikErrors<FormBeneficiaryExpressValues>;
  onChangeValues?: (_: FormBeneficiaryExpressValues) => void;
}

export const formBeneficiaryExpressValuesToDto = (
  values: FormBeneficiaryExpressValues,
) => {
  const payload: BeneficiaryRequestPost = {
    beneficiaryId: values?.id,
    type: values.type,
    birthdate: values!.birthDate as Date,
    regimeCode: values.regime,
    postalCode: values.postalCode,
  };

  return payload;
};

export const badRequestToFormBeneficiaryExpressErrors = (
  errors: BeneficiaryRequestPostErrors,
) => {
  const payload: FormBeneficiaryExpressErrors = {
    birthDate: errors.birthdate,
    regime: errors.regimeCode,
    postalCode: errors.postalCode,
  };

  return payload;
};

const FormBeneficiaryExpress = forwardRef(
  (props: FormBeneficiaryExpressProps, ref: any) => {
    // ForwardRef methods.
    useImperativeHandle(ref, () => ({
      resetForm: () => {
        formikRef.current!.resetForm();
      },
      validateForm: () => {
        formikRef.current!.setTouched({
          postalCode: true,
          birthDate: true,
          regime: true,
        });
        return formikRef.current!.validateForm();
      },
      get values() {
        return formikRef.current!.values;
      },
    }));

    // Form.
    const formikRef = useRef<FormikProps<FormBeneficiaryExpressValues>>(null);

    const ageMin =
      props.type === BeneficiaryType.CHILD ? ageChildMin : ageAdultMin;
    const ageMax =
      props.type === BeneficiaryType.CHILD ? ageChildMax : ageAdultMax;

    const validationSchema = Yup.object().shape({
      type: Yup.string(),
      postalCode: yupFieldPostalCode.when('type', {
        is: (value: string) => value === BeneficiaryType.SUBSCRIBER.toString(),
        then: schema => schema.required(messageFieldRequired),
      }),
      birthDate: (props.type === BeneficiaryType.CHILD
        ? getYupFieldBirthDateChild()
        : getYupFieldBirthDateAdult()
      ).required(messageFieldRequired),
      regime: Yup.string().required(messageFieldRequired),
    });

    return (
      <Formik
        innerRef={formikRef}
        initialValues={
          props.initialValues || {
            type: props.type,
            postalCode: undefined,
            birthDate: undefined,
            regime: undefined,
          }
        }
        validationSchema={validationSchema}
        onSubmit={(
          values,
          { setSubmitting }: FormikHelpers<FormBeneficiaryExpressValues>,
        ) => {
          setSubmitting(false);
        }}
      >
        {({
          errors,
          touched,
          values,
          setFieldValue,
          setFieldTouched,
          setFieldError,
          setErrors,
        }) => {
          useEffect(() => {
            if (Object.keys(values).length > 0 && props.onChangeValues) {
              props.onChangeValues(values);
            }
          }, [values]);

          useEffect(() => {
            if (!props.errors) return;
            setErrors({ ...errors, ...props.errors });
            Object.entries(props.errors).forEach(_ => {
              setFieldTouched(_[0], true, false);
            });
          }, [props.errors]);

          return (
            <Form>
              {props.onChangeValues && (
                <FormikObserver onChange={props.onChangeValues} />
              )}

              <Grid
                container
                spacing={{
                  sm: 2,
                  xs: 1,
                }}
              >
                {props.type === BeneficiaryType.SUBSCRIBER && (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required={props.type === BeneficiaryType.SUBSCRIBER}
                      id="postalCode"
                      name="postalCode"
                      label="Code postal"
                      value={values.postalCode}
                      error={touched.postalCode && Boolean(errors.postalCode)}
                      helperText={
                        touched.postalCode && <>{errors.postalCode}</>
                      }
                      onChange={e =>
                        setFieldValue('postalCode', e.target.value)
                      }
                      onBlur={e => setFieldTouched('postalCode')}
                      inputProps={{ minLength: 5, maxLength: 5 }}
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <DatePicker
                    sx={{
                      width: '100%',
                    }}
                    label="Date de naissance"
                    format="DD/MM/YYYY"
                    openTo="year"
                    value={values.birthDate}
                    minDate={dayjs(getDateFromAge(ageMax))}
                    maxDate={dayjs(getDateFromAge(ageMin))}
                    onChange={v => setFieldValue('birthDate', v)}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        required: true,
                        onBlur: e => setFieldTouched('birthDate'),
                        error: touched.birthDate && Boolean(errors.birthDate),
                        helperText: touched.birthDate &&
                          Boolean(errors.birthDate) && <>{errors.birthDate}</>,
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <RegimeSelect
                    fullWidth
                    required
                    id="regime"
                    name="regime"
                    label="Régime social"
                    value={values.regime}
                    error={!!touched.regime && Boolean(errors.regime)}
                    helperText={touched.regime && errors.regime}
                    onChange={e => setFieldValue('regime', e.target.value)}
                    onBlur={() => setFieldTouched('regime')}
                  />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  },
);

export default memo(FormBeneficiaryExpress);
