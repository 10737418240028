import { Person } from './Person.models';

export enum BeneficiaryType {
  SUBSCRIBER,
  PARTNER,
  CHILD,
}

export const enumBeneficiaryTypes = [
  {
    value: BeneficiaryType.SUBSCRIBER,
    label: 'Adhérent',
  },
  {
    value: BeneficiaryType.PARTNER,
    label: 'Conjoint',
  },
  {
    value: BeneficiaryType.CHILD,
    label: 'Enfant',
  },
];

export type Beneficiary = {
  id?: string;
  techDateCreated?: Date;
  techDateModification?: Date;
  type: BeneficiaryType;
  person: Person;
};

export type BeneficiaryRequestPost = {
  beneficiaryId?: string;
  type: BeneficiaryType;
  personId?: string;
  birthdate?: Date;
  regimeCode?: string;
  postalCode?: string;
};

export type BeneficiaryRequestPostErrors = {
  beneficiaryId?: string;
  type?: string;
  personId?: string;
  birthdate?: string;
  regimeCode?: string;
  postalCode?: string;
};

export const beneficiaryToRequestPost = (entity: Beneficiary) => {
  return;
};
