import React from 'react';
import { useFormikContext } from 'formik';
import Grid from '@mui/material/Grid';
import TextField from 'components/TextField/TextField';
import { AddressFormValues } from 'models/Form.model';
import { postalCodeSize } from 'utils/validation/yupPerson';

export const AddressFrom = () => {
  const { values, errors, touched, handleChange, setFieldValue, handleBlur } =
    useFormikContext<AddressFormValues>();

  return (
    <>
      <Grid item xs={6}>
        <TextField
          fullWidth
          id="addressLine1"
          name="addressLine1"
          label="N° et nom de voie"
          value={values.addressLine1}
          error={touched.addressLine1 && !!errors.addressLine1}
          helperText={touched.addressLine1 && errors.addressLine1}
          onChange={handleChange}
          onBlur={handleBlur}
          inputProps={{ minLength: 1, maxLength: 100 }}
        />
      </Grid>
      <Grid item xs={6} />
      <Grid item xs={6}>
        <TextField
          fullWidth
          id="addressLine2"
          name="addressLine2"
          label="Complément d'adresse 1"
          value={values.addressLine2}
          error={touched.addressLine2 && !!errors.addressLine2}
          helperText={touched.addressLine2 && errors.addressLine2}
          onChange={handleChange}
          onBlur={handleBlur}
          inputProps={{ minLength: 1, maxLength: 100 }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          id="addressLine3"
          name="addressLine3"
          label="Complément d'adresse 2"
          value={values.addressLine3}
          error={touched.addressLine3 && !!errors.addressLine3}
          helperText={touched.addressLine3 && errors.addressLine3}
          onChange={handleChange}
          onBlur={handleBlur}
          inputProps={{ minLength: 1, maxLength: 100 }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          id="postalCode"
          name="postalCode"
          label="Code postal"
          value={values.postalCode}
          error={touched.postalCode && !!errors.postalCode}
          helperText={touched.postalCode && errors.postalCode}
          onChange={e =>
            setFieldValue('postalCode', e.target.value.replaceAll(/\D/g, ''))
          }
          onBlur={handleBlur}
          inputProps={{ minLength: postalCodeSize, maxLength: postalCodeSize }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          id="city"
          name="city"
          label="Ville"
          value={values.city}
          error={touched.city && !!errors.city}
          helperText={touched.city && errors.city}
          onChange={handleChange}
          onBlur={handleBlur}
          inputProps={{ minLength: 1, maxLength: 100 }}
        />
      </Grid>
    </>
  );
};
