import { useAuth } from 'react-oidc-context';
import { ApiResponse } from 'models/api.model';
import { RestError } from 'errors/RestError';

export const useAccessToken = (): string => {
  const auth = useAuth();
  const accessToken = auth.user?.access_token;

  if (!accessToken) {
    throw new Error(
      'Fetch could not be performed, secured endpoint requested without being authenticated.',
    );
  }
  return accessToken;
};

export const isBadRequestWithDetailsError = <T>(
  error: RestError<ApiResponse<T>> | Error,
): error is RestError<ApiResponse<T>> => {
  return (
    (error as RestError).status === 400 &&
    !!(error as RestError<ApiResponse<T>>).response?.details
  );
};
