import { IconProps } from './settings';
import { Icon } from '.';

export const NeedOpticalIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M36.6653 17.4538C37.9838 18.931 37.9838 21.069 36.6653 22.5462C34.9807 24.4337 32.5594 26.8128 29.6674 28.7141C26.7709 30.6182 23.4683 32 20 32C16.5317 32 13.2291 30.6182 10.3326 28.7141C7.44057 26.8128 5.01932 24.4337 3.3347 22.5462C2.01619 21.069 2.01619 18.931 3.3347 17.4538C5.01932 15.5663 7.44057 13.1872 10.3326 11.2859C13.2291 9.38178 16.5317 8 20 8C23.4683 8 26.7709 9.38178 29.6674 11.2859C32.5594 13.1872 34.9807 15.5663 36.6653 17.4538Z"
          stroke="currentColor"
          strokeWidth="2"
        />
        <circle cx="20" cy="20" r="8" stroke="currentColor" strokeWidth="2" />
        <circle
          cx="20"
          cy="20"
          r="3"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="2"
        />
      </svg>
    </Icon>
  );
};
