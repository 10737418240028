import React, { useMemo } from 'react';

import { Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import { Form, Formik } from 'formik';
import IdentityForm from 'content/form/PersonForm/IdentityForm';
import ContactInfoForm from 'content/form/PersonForm/ContactInfoForm';
import { PersonFormValues } from 'models/Form.model';
import { usePutPersonMutation } from 'queries';
import { Person } from 'models/oav/Person.models';
import {
  personFormValuesToRequest,
  personToInitialFormValues,
  personValidationSchema,
} from '../personForm';
import { PersonTab, PersonTabs } from '../PersonTabs';

interface PersonUpdateProps {
  person: Person;
  onBackToViewMode: () => void;
}

const Buttons = (props: { onCancel: () => void }) => (
  <Grid item xs={12}>
    <Stack
      direction="row"
      alignItems="center"
      flexWrap="wrap"
      useFlexGap
      spacing={2}
      justifyContent="flex-end"
      sx={{ mt: 2 }}
    >
      <Button
        color="default"
        size="medium"
        onClick={props.onCancel}
        sx={{ px: 2 }}
      >
        Annuler
      </Button>
      <Button size="medium" type="submit" sx={{ px: 2 }}>
        Enregistrer
      </Button>
    </Stack>
  </Grid>
);

export const PersonUpdate = (props: PersonUpdateProps) => {
  const handleError = (error: Error) => {
    console.error(error);
  };

  const mutation = usePutPersonMutation(props.onBackToViewMode, handleError);

  const handleSubmit = (values: PersonFormValues) => {
    if (!mutation.isPending) {
      const personRequest = personFormValuesToRequest(values);
      mutation.mutate({ person: personRequest, uuid: props.person.id! });
    }
  };

  const initialValues = useMemo(
    () => personToInitialFormValues(props.person),
    [],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={personValidationSchema}
      validateOnMount={true}
    >
      {() => (
        <Form>
          <PersonTabs gap="large">
            <PersonTab tab="identity">
              <IdentityForm />
              <Buttons onCancel={props.onBackToViewMode} />
            </PersonTab>
            <PersonTab tab="contact-info">
              <ContactInfoForm />
              <Buttons onCancel={props.onBackToViewMode} />
            </PersonTab>
          </PersonTabs>
        </Form>
      )}
    </Formik>
  );
};
