import {
  TablePaginationProps,
  TablePagination as MuiTablePagination,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ChevronBottomIcon } from 'components/Icon/ChevronBottomIcon';
import React from 'react';

export const TablePagination = (props: TablePaginationProps) => {
  const theme = useTheme();
  return (
    <MuiTablePagination
      {...props}
      slotProps={{
        ...props.slotProps,
        select: {
          ...props.slotProps?.select,
          inputProps: {
            ...props.slotProps?.select?.inputProps,
            sx: {
              ...props.slotProps?.select?.inputProps?.sx,
              '&.MuiInputBase-input': {
                '&.MuiInputBase-input': {
                  paddingRight: '40px',
                  textAlign: 'left',
                },
              },
            },
          },
          IconComponent: params => (
            <ChevronBottomIcon
              {...params}
              width="15px"
              height="15px"
              style={{
                color: theme.palette.text.secondary,
                top: 'calc(50% - 8px)',
              }}
            />
          ),
        },
      }}
    />
  );
};
