import React from 'react';
import { Outlet } from 'react-router-dom';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import Sidebar from '../../Sidebar';
import { UserProvider } from '../../Context/UserContext';

const LayoutApp: React.FC = () => {
  return (
    <UserProvider>
      <Box
        sx={{
          display: 'flex',
          overflow: 'hidden',
        }}
      >
        <Sidebar />
        <Stack
          sx={{
            width: '100%',
            height: '100vh',
            overflow: 'overlay',
            p: 2,
          }}
        >
          <Outlet />
        </Stack>
      </Box>
    </UserProvider>
  );
};

export default LayoutApp;
