import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { appName } from '../App';
import { ArrowCircleRightIcon } from '../components/Icon/ArrowCircleRightIcon';

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  document.title = `404 - ${appName}`;

  return (
    <Box
      sx={{
        p: 2,
        minHeight: '100vh',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h1" sx={{ fontSize: '64px !important' }}>
            Oups !
            <Chip
              variant="outlined"
              color="warning"
              label={<Typography variant="h2">404</Typography>}
              sx={{ ml: 2 }}
            />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>La page que vous cherchez est introuvable.</Typography>
          {/* <pre style={{display: 'inline-flex'}}>{location.pathname}</pre> */}
        </Grid>
        <Grid item xs={12}>
          <Button
            color="default"
            onClick={() => navigate('/dashboard')}
            startIcon={
              <ArrowCircleRightIcon style={{ transform: 'rotate(180deg)' }} />
            }
          >
            Retour à l'accueil
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NotFoundPage;
