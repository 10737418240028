import { queryOptions } from '@tanstack/react-query';

import { useAccessToken } from 'utils/api/api';
import { fetchSubscriptionTarification } from './Subscription.api';

export const subscriptionQueries = {
  getByIdTarification: (projectId: string, id: string) =>
    subscriptionGetByIdTarificationOptions(projectId, id),
};

export const subscriptionGetByIdTarificationOptions = (
  projectId: string,
  id: string,
) => {
  const token = useAccessToken();

  return queryOptions({
    queryKey: ['projects', projectId, 'subscription', id, 'tarification'],
    queryFn: () => fetchSubscriptionTarification(token, projectId, id),
    staleTime: 10 * 60 * 1000,
    gcTime: 10 * 60 * 1000,
  });
};
