import { Icon } from '.';
import { IconProps } from './settings';

export const CheckCircleFilledIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM18.9581 7.37325C19.114 7.14535 19.0557 6.83419 18.8278 6.67826C18.5999 6.52232 18.2887 6.58066 18.1328 6.80857L11.4675 16.5501L6.96604 9.5478C6.81672 9.31552 6.50736 9.24827 6.27508 9.39759C6.04279 9.54692 5.97554 9.85628 6.12487 10.0886L11.034 17.7249L11.4416 18.359L11.8672 17.7369L18.9581 7.37325Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  );
};
