import { Icon } from '.';
import { IconProps } from './settings';

export const ProjectIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
          x="4"
          y="2"
          width="22"
          height="26"
          rx="1"
          stroke="currentColor"
          strokeWidth="2"
        />
        <path
          d="M8 22H22"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17 10L22 10"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17 17H22"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 8L9.2414 9.8621C9.61683 10.4252 10.4315 10.4606 10.8543 9.9321L14 6"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 15L9.2414 16.8621C9.61683 17.4252 10.4315 17.4606 10.8543 16.9321L14 13"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Icon>
  );
};
