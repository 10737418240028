import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Offer } from 'models/referentiels/Offer.model';
import { getRiskColor } from 'models/referentiels/Risk.model';
import { imageMapping } from 'utils/offer/imageMapping';

export type CardOfferProps = {
  offer: Offer;
  selected?: boolean;
  hideCheckbox?: boolean;
  showType?: boolean;
  error?: boolean;
  helperText?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const CardOffer = (props: CardOfferProps) => {
  const theme = useTheme();

  return (
    <Card
      onClick={e => {
        if (props.onClick) props.onClick(e);
      }}
      sx={{
        height: '100%',
        borderRadius: 4,
        p: 2,
        border: 2,
        borderColor: props.error
          ? theme.palette.error.main
          : props.selected
            ? theme.palette.primary.main
            : theme.palette.divider,
        cursor: props.onClick ? 'pointer' : 'default',
        transition: 'transform 150ms ease-in-out',
        ':hover': {
          transform: props.onClick ? 'translateY(-6px)' : 'none',
        },
      }}
    >
      <CardContent
        sx={{
          height: '100%',
          p: 0,
        }}
      >
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={1}
              sx={{
                mt: -1,
                ml: -1,
              }}
            >
              {!props.hideCheckbox && <Checkbox checked={props.selected} />}

              <Stack
                direction="row-reverse"
                spacing={1}
                flexWrap="wrap"
                useFlexGap
                sx={{
                  pt: 1,
                }}
              >
                {props.offer.risks?.map(_ => {
                  const color = getRiskColor(_.code);

                  return (
                    <Chip
                      key={_.id}
                      label={_.label}
                      size="small"
                      sx={{
                        fontSize: '0.9rem',
                        fontWeight: 700,
                        color: color
                          ? theme.palette.background.default
                          : theme.palette.text.primary,
                        background: color
                          ? `linear-gradient(270deg, ${color.main} 0%, ${color.secondary} 100%)`
                          : theme.palette.background.default,
                      }}
                    />
                  );
                })}

                <Chip
                  label={`${props.offer.formulas.length} formules`}
                  size="small"
                />
              </Stack>
            </Stack>
          </Grid>

          {/* TODO */}
          <Grid item xs={12}>
            <img
              src={imageMapping[props.offer.code]}
              width="100%"
              style={{
                objectFit: 'cover',
                maxHeight: 75,
                borderRadius: 8,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h3">{props.offer.label}</Typography>
            <Typography variant="body2">{props.offer.description}</Typography>
          </Grid>
        </Grid>
      </CardContent>

      {props.showType ||
        (props.helperText && (
          <CardActions sx={{ p: 0, pb: 2 }}>
            {props.helperText && (
              <Grid item xs={12}>
                <Typography
                  variant="caption"
                  color={props.error ? 'error' : 'default'}
                >
                  {props.helperText}
                </Typography>
              </Grid>
            )}

            {props.showType && (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  mt: 'auto',
                  width: '100%',
                }}
              >
                {/* TODO : Add to theme palette */}
                <Chip
                  variant="filled"
                  size="small"
                  label="Offre"
                  sx={{
                    background:
                      'linear-gradient(270deg, #98AFFF 0%, #B880DB 100%)',
                    '& .MuiChip-label': {
                      color: theme.palette.background.default,
                      fontWeight: 700,
                    },
                  }}
                />
              </Stack>
            )}
          </CardActions>
        ))}
    </Card>
  );
};

export default CardOffer;
