import React, { ChangeEventHandler, forwardRef, useMemo } from 'react';
import { useFormikContext } from 'formik';
import dayjs from 'dayjs';
import Grid from '@mui/material/Grid';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputBaseComponentProps,
  Radio,
  RadioGroup,
} from '@mui/material';
import TextField from 'components/TextField/TextField';
import DatePicker from 'components/DatePicker/DatePicker';
import {
  ageAdultMax,
  ageChildMin,
  birthOrderMax,
  birthOrderMin,
  cpamIdSize,
  getDateFromAge,
  nameMaxChar,
  nameMinChar,
} from 'utils/validation/yupPerson';
import { RegimeSelect } from 'components/Select/RegimeSelect';
import { PersonFormValues } from 'models/Form.model';
import {
  Civility,
  civilityLabels,
  Gender,
  genderLabels,
} from 'models/oav/Person.models';
import { NNITextField } from 'components/TextField/formatted/NNITextField';

const IdentityForm = () => {
  const { values, errors, touched, handleChange, handleBlur, setFieldValue } =
    useFormikContext<PersonFormValues>();

  return (
    <>
      <Grid item xs={6}>
        <TextField
          fullWidth
          required
          id="firstname"
          name="firstname"
          label="Prénom"
          value={values.firstname}
          error={touched.firstname && !!errors.firstname}
          helperText={touched.firstname && errors.firstname}
          onChange={handleChange}
          onBlur={handleBlur}
          inputProps={{
            minLength: nameMinChar,
            maxLength: nameMaxChar,
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          required
          id="lastname"
          name="lastname"
          label="Nom"
          value={values.lastname}
          error={touched.lastname && !!errors.lastname}
          helperText={touched.lastname && errors.lastname}
          onChange={handleChange}
          onBlur={handleBlur}
          inputProps={{
            minLength: nameMinChar,
            maxLength: nameMaxChar,
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          sx={{
            width: '100%',
          }}
          label="Date de naissance"
          format="DD/MM/YYYY"
          openTo="year"
          name="birthdate"
          value={values.birthdate}
          minDate={dayjs(getDateFromAge(ageAdultMax))}
          maxDate={dayjs(getDateFromAge(ageChildMin))}
          onChange={v => setFieldValue('birthdate', v)}
          slotProps={{
            textField: {
              fullWidth: true,
              required: true,
              onBlur: handleBlur,
              error: !!touched.birthdate && !!errors.birthdate,
              helperText: !!touched.birthdate && <>{errors.birthdate}</>,
            },
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <NNITextField
          fullWidth
          id="nni"
          name="nni"
          label="N° Sécurité sociale"
          value={values.nni}
          error={touched.nni && !!errors.nni}
          helperText={touched.nni && errors.nni}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl sx={{ px: 2 }}>
          <FormLabel id="identity-form-civility">Civilité *</FormLabel>
          <RadioGroup
            row
            aria-labelledby="identity-form-civility"
            name="civility"
            value={values.civility}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <FormControlLabel
              value={Civility.M}
              control={<Radio />}
              label={civilityLabels[Civility.M].label}
            />
            <FormControlLabel
              value={Civility.Mme}
              control={<Radio />}
              label={civilityLabels[Civility.Mme].label}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl sx={{ px: 2 }}>
          <FormLabel id="identity-form-gender">Genre</FormLabel>
          <RadioGroup
            row
            aria-labelledby="identity-form-gender"
            name="gender"
            value={values.gender}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <FormControlLabel
              value={Gender.WOMAN}
              control={<Radio />}
              label={genderLabels[Gender.WOMAN].label}
            />
            <FormControlLabel
              value={Gender.MAN}
              control={<Radio />}
              label={genderLabels[Gender.MAN].label}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          id="birthOrder"
          name="birthOrder"
          label="Rang de naissance"
          type="number"
          value={values.birthOrder}
          error={touched.birthOrder && !!errors.birthOrder}
          helperText={touched.birthOrder && errors.birthOrder}
          onChange={handleChange}
          onBlur={handleBlur}
          inputProps={{
            min: birthOrderMin,
            max: birthOrderMax,
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <RegimeSelect
          fullWidth
          id="regime"
          name="regime"
          label="Régime social"
          value={values.regime}
          error={!!touched.regime && !!errors.regime}
          helperText={touched.regime && errors.regime}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          id="cpamId"
          name="cpamId"
          label="N° Organisme d'affiliation"
          value={values.cpamId}
          error={touched.cpamId && !!errors.cpamId}
          helperText={touched.cpamId && errors.cpamId}
          onChange={e =>
            setFieldValue('cpamId', e.target.value.replaceAll(/\D/g, ''))
          }
          onBlur={handleBlur}
          inputProps={{ minLength: cpamIdSize, maxLength: cpamIdSize }}
        />
      </Grid>
    </>
  );
};

export default IdentityForm;
