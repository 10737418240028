import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { appName } from 'App';
import AppBar from 'components/AppBar';
import { ButtonNewProject } from 'components/Button/ButtonNewProject/ButtonNewProject';

const ProjectPage: React.FC = () => {
  document.title = `Projets - ${appName}`;

  return (
    <AppBar>
      <Stack
        direction="row"
        justifyContent="space-between"
        flexWrap="wrap"
        useFlexGap
        spacing={1}
        sx={{
          width: '100%',
        }}
      >
        <Typography variant="h2">Projets</Typography>
        <ButtonNewProject />
      </Stack>
    </AppBar>
  );
};

export default ProjectPage;
