import { cloneElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';

import { PersonCircleIllustrationIcon } from 'components/Icon/PersonCircleIllustrationIcon';
import { CompanyCircleIllustrationIcon } from 'components/Icon/CompanyCircleIllustrationIcon';

export const ButtonNewProject = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [anchorProjectMenuEl, setAnchorProjectMenuEl] =
    useState<null | HTMLElement>(null);

  const openProjectMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorProjectMenuEl(event.currentTarget);
  };

  const closeProjectMenu = () => {
    if (anchorProjectMenuEl) {
      anchorProjectMenuEl.focus();
    }
    setAnchorProjectMenuEl(null);
  };

  const projectMenuItems = [
    {
      label: 'Un particulier ou TNS',
      icon: <PersonCircleIllustrationIcon />,
      onClick: () => {
        navigate('/projets/nouveau');
      },
    },
    {
      label: 'Une Entreprise',
      description: 'Vous serez redirigé vers GSMC Pro',
      icon: <CompanyCircleIllustrationIcon />,
      onClick: () => {
        window.open(
          'https://www.mutuelle-gsmc.fr/courtier/oav/devis',
          '_blank',
        );
      },
    },
  ];

  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      flexWrap="wrap"
      useFlexGap
      spacing={1}
    >
      <Button onClick={openProjectMenu} variant="gradient" color="secondary">
        Nouveau projet
      </Button>
      <Menu
        anchorEl={anchorProjectMenuEl}
        open={anchorProjectMenuEl !== null}
        onClose={closeProjectMenu}
        slotProps={{
          paper: {
            sx: {
              background: theme.palette.background.paper,
            },
          },
        }}
      >
        <Typography fontWeight={700} sx={{ px: 2, pb: 1 }}>
          Qui accompagnez-vous ?
        </Typography>

        {projectMenuItems.map(_ => (
          <MenuItem onClick={_.onClick}>
            <ListItemIcon>
              {_.icon && cloneElement(_.icon, { color: 'default' })}
            </ListItemIcon>
            <ListItemText>
              <Typography>{_.label}</Typography>
              {_.description && (
                <Typography variant="caption">{_.description}</Typography>
              )}
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
};
