import { PaletteOptions } from '@mui/material/styles';

export const paletteBlue: PaletteOptions = {
  primary: {
    main: '#5FDED6',
  },
  secondary: {
    main: '#F89136',
    contrastText: '#FAFAFA',
  },
  error: {
    main: '#FF5D5D',
  },
  warning: {
    main: '#F89136',
    contrastText: '#FAFAFA',
  },
  info: {
    main: '#0884CA',
  },
  success: {
    main: '#52E09C',
  },
  text: {
    primary: '#FAFAFA',
    secondary: '#AFB6C8',
    disabled: '#AFB6C8',
  },
  background: {
    default: '#1D2943',
    paper: '#3B455E',
  },
  action: {
    disabled: '#AFB6C8',
    disabledBackground: '#888DA2',
  },
  divider: '#475370',
};
