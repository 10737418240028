import { useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Link as LinkMUI } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { appName } from 'App';
import AppBar from 'components/AppBar';
import { ChevronRightIcon } from 'components/Icon/ChevronRightIcon';
import FormBeneficiariesExpress, {
  FormBeneficiariesExpressRef,
} from 'content/form/FormBeneficiariesExpress';
import { Project } from 'models/oav/Project.models';

const ProjectNewPage: React.FC = () => {
  document.title = `Nouveau Projet - ${appName}`;

  const navigate = useNavigate();
  const refForm = useRef<FormBeneficiariesExpressRef>(null);

  const submit = async () => {
    refForm.current?.submitForm().then((_: Project) => {
      if (_) navigate(`/projets/${_.id}/offres`);
    });
  };

  return (
    <>
      <AppBar>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          useFlexGap
          spacing={1}
          sx={{
            width: '100%',
          }}
        >
          <Breadcrumbs separator={<ChevronRightIcon size="small" />}>
            <Link to="/projets" style={{ color: 'inherit' }}>
              <LinkMUI underline="hover" variant="body2" color="inherit">
                Projets
              </LinkMUI>
            </Link>
            <Typography color="text.primary">Nouveau</Typography>
          </Breadcrumbs>
        </Stack>
      </AppBar>

      <Box
        sx={{
          maxWidth: 750,
          mx: 'auto',
          mt: 2,
        }}
      >
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h3">Qui souhaitez-vous couvrir ?</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormBeneficiariesExpress ref={refForm} maxChildren={3} />
          </Grid>
          <Grid item sm="auto" xs={12}>
            <Button
              fullWidth
              onClick={() => {
                submit();
              }}
              sx={{ px: 4 }}
            >
              Valider
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ProjectNewPage;
