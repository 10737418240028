import { RestError } from 'errors/RestError';
import { WarrantyAcronym } from 'models/referentiels/Warranty.model';

const baseUrl = `${process.env.REACT_APP_API_URI_BASE}/referentiels/warranties`;

export const fetchWarrantyAcronymAll = async (
  accessToken: string,
): Promise<WarrantyAcronym[]> => {
  const url = `${baseUrl}/acronyms`;

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'GET',
      undefined,
      await response.json(),
    );

  return response.json();
};
