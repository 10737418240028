import { PaletteOptions } from '@mui/material/styles';

export const paletteLight: PaletteOptions = {
  primary: {
    main: '#7992EA',
    contrastText: '#FAFAFA',
  },
  secondary: {
    main: '#F89136',
    contrastText: '#FAFAFA',
  },
  error: {
    main: '#F23A3A',
    contrastText: '#FAFAFA',
  },
  warning: {
    main: '#F89136',
    contrastText: '#FAFAFA',
  },
  info: {
    main: '#2155CD',
    contrastText: '#FAFAFA',
  },
  success: {
    main: '#52E09C',
    contrastText: '#FAFAFA',
  },
  text: {
    primary: '#303030',
    secondary: '#929596',
    disabled: '#656869',
  },
  background: {
    default: '#EEEEEE',
    paper: '#DFE2E1',
  },
  action: {
    disabled: '#656869',
    disabledBackground: '#929596',
  },
  divider: '#929596',
};
