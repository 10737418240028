import dayjs from 'dayjs';
import * as Yup from 'yup';

export const dateStartDayMin = 1;
export const dateStartDayMax = 365;

export const getYupFieldDateStart = (from?: Date) => {
  return Yup.date()
    .typeError('Date non valide')
    .nullable()
    .test(
      'min',
      `La date de début doit être ultérieure au ${dayjs(from).startOf('day').add(dateStartDayMin, 'day').format('DD/MM/YYYY')}`,
      value =>
        !dayjs(value)
          .startOf('day')
          .isBefore(dayjs(from).startOf('day').add(dateStartDayMin, 'day')),
    )
    .test(
      'max',
      `La date de début doit être antérieure au ${dayjs(from).startOf('day').add(dateStartDayMax).format('DD/MM/YYYY')}`,
      value =>
        !dayjs(value)
          .startOf('day')
          .isAfter(dayjs(from).startOf('day').add(dateStartDayMax, 'day')),
    );
};
