import dayjs from 'dayjs';
import { WrongStatusError } from 'api/referentiels';
import { Pagination } from 'models/Page.models';
import {
  Person,
  PersonRequest,
  PersonSearchCriteriaRequest,
} from 'models/oav/Person.models';
import { addParams } from 'utils/api/params';

const url = `${process.env.REACT_APP_API_URI_BASE}/oav/person`;

export const postPerson = async (
  person: PersonRequest,
  accessToken: string,
): Promise<Person> => {
  const response = await fetch(url, {
    body: JSON.stringify({
      ...person,
      birthdate: dayjs(person.birthdate).format('YYYY-MM-DD'),
    }),
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
  if (response.status !== 200) {
    throw new WrongStatusError(response.status, 'Wrong http status.');
  }

  const json = await response.json();
  return {
    ...json,
    birthdate: json.birthdate && new Date(json.birthdate),
  } as Person;
};

export const putPerson = async (
  person: PersonRequest,
  uuid: string,
  accessToken: string,
): Promise<Person> => {
  const response = await fetch(`${url}/${uuid}`, {
    body: JSON.stringify({
      ...person,
      birthdate: dayjs(person.birthdate).format('YYYY-MM-DD'),
    }),
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
  if (response.status !== 200) {
    throw new WrongStatusError(response.status, 'Wrong http status.');
  }

  const json = await response.json();
  return {
    ...json,
    birthdate: json.birthdate && new Date(json.birthdate),
  } as Person;
};

export const fetchPerson = async (
  uuid: string,
  accessToken: string,
): Promise<Person> => {
  const response = await fetch(`${url}/${uuid}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
    },
  });
  if (response.status !== 200) {
    throw new WrongStatusError(response.status, 'Wrong http status.');
  }
  const json = await response.json();
  return {
    ...json,
    birthdate: json.birthdate && new Date(json.birthdate),
  } as Person;
};

interface PersonListResponse {
  page: number;
  pageSize: number;
  persons: Person[];
  total: number;
}

export const fetchPersonList = async (
  pagination: Pagination,
  criteria: PersonSearchCriteriaRequest,
  accessToken: string,
): Promise<PersonListResponse> => {
  const params = new URLSearchParams();
  params.append('page', pagination.page!.toString());
  params.append('pageSize', pagination.size!.toString());
  const formattedCriteria = {
    ...criteria,
    birthdate: criteria.birthdate
      ? dayjs(criteria.birthdate).format('YYYY-MM-DD')
      : undefined,
  };
  addParams(params, formattedCriteria, 'criterias');

  const response = await fetch(`${url}?${params}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
    },
  });
  if (response.status !== 200) {
    throw new WrongStatusError(response.status, 'Wrong http status.');
  }
  return await response.json();
};
