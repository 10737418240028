import { ChangeEvent, useMemo, useState } from 'react';
import { useRisks, useSegments } from 'queries';
import { useDebounce } from 'use-debounce';
import { useNavigate } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { appName } from 'App';
import AppBar from 'components/AppBar';
import CardOffer from 'components/Card/CardOffer';
import TextField from 'components/TextField/TextField';
import { SearchIcon } from 'components/Icon/SearchIcon';
import SelectFilter from 'components/Select/SelectFilter';
import { useFilter } from './hooks/filters';
import FilterChip from './FilterChip';
import { Risk } from 'models/referentiels/Risk.model';
import { Segment } from 'models/referentiels/Segment';
import Skeleton from '@mui/material/Skeleton';
import { useQuery } from '@tanstack/react-query';
import { offerQueries } from 'api/referentiels/OfferQuery.api';

const OffersPage = () => {
  document.title = `Bibliothèque - ${appName}`;

  const theme = useTheme();
  const navigate = useNavigate();

  const [risks, handleChangeRisks, resetRisks, removeRisk] = useFilter<Risk>();
  const [segments, handleChangeSegments, resetSegments, removeSegment] =
    useFilter<Segment>();

  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText] = useDebounce(searchText, 300);

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.currentTarget.value);
  };

  const filters = useMemo(
    () => ({
      inRisks: risks.map(r => r.code) || undefined,
      inSegments: segments.map(s => s.code) || undefined,
      search: debouncedSearchText || undefined,
    }),
    [risks, segments, debouncedSearchText],
  );

  const { data: offers } = useQuery(offerQueries.getAll());

  const { data: segmentOptions } = useSegments();

  const { data: riskOptions } = useRisks();

  const showClearAll = risks.length > 0 || segments.length > 0;

  const clearAll = () => {
    resetRisks();
    resetSegments();
  };

  return (
    <>
      <AppBar>
        <Box
          sx={{
            width: '100%',
            pb: 2,
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
            useFlexGap
            spacing={1}
            sx={{
              width: '100%',
            }}
          >
            <Typography variant="h2">Bibliothèque d'offres</Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={searchText}
              placeholder="Recherche"
              onChange={handleChangeSearch}
              sx={{
                maxWidth: '500px',
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color={theme.palette.text.secondary} />
                  </InputAdornment>
                ),
              }}
            />
            <Link target="_blank" href={process.env.REACT_APP_GSMC_PRO_URL}>
              <Button size="medium" variant="gradient">
                Accéder à GSMC Pro
              </Button>
            </Link>
          </Stack>
        </Box>
      </AppBar>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row" flexWrap="wrap" useFlexGap spacing={2}>
              <SelectFilter
                disabled={false}
                onChange={handleChangeRisks}
                multiple={true}
                showCount={true}
                options={riskOptions}
                value={risks}
                getOptionKey={option => option.code}
                renderElement={option => option.label}
              >
                Risque
              </SelectFilter>
              <SelectFilter
                disabled={false}
                onChange={handleChangeSegments}
                multiple={true}
                showCount={true}
                options={segmentOptions}
                value={segments}
                getOptionKey={option => option.code}
                renderElement={option => option.label}
              >
                Segment
              </SelectFilter>
            </Stack>

            <Typography
              variant="body1"
              sx={{
                display: {
                  sm: 'flex',
                  xs: 'none',
                },
              }}
            >
              Total des offres :&nbsp;
              <Chip
                label={offers?.length ?? 0}
                color="default"
                sx={{
                  backgroundColor: theme.palette.background.paper,
                  fontWeight: 'bold',
                }}
                variant="filled"
                size="small"
              />
            </Typography>
          </Stack>
        </Grid>
        {(risks.length > 0 || segments.length > 0) && (
          <Grid item xs={12}>
            <Stack
              direction="row"
              alignItems="center"
              flexWrap="wrap"
              useFlexGap
              spacing={2}
            >
              {risks.map(item => (
                <FilterChip
                  key={item.code}
                  item={item}
                  theme={theme}
                  onDelete={removeRisk(item)}
                />
              ))}
              {segments.map(item => (
                <FilterChip
                  key={item.code}
                  item={item}
                  theme={theme}
                  onDelete={removeSegment(item)}
                />
              ))}
              {showClearAll && <Link onClick={clearAll}>Tout effacer</Link>}
            </Stack>
          </Grid>
        )}

        <Grid item xs={12}>
          <Grid container spacing={2}>
            {!offers ? (
              <Grid item xs={12}>
                <Skeleton variant="rectangular" animation="wave" height={200} />
              </Grid>
            ) : (
              offers?.map(_ => (
                <Grid key={_.id} item xl={3} lg={4} sm={6} xs={12}>
                  <CardOffer
                    offer={_}
                    onClick={() => navigate(`/offres/${_.code}`)}
                    hideCheckbox
                    showType
                  />
                </Grid>
              ))
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default OffersPage;
