import { Icon } from '.';
import { IconProps } from './settings';

export const CloseIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        {/* <circle cx="15" cy="15" r="14" stroke='currentColor' strokeWidth="2"/> */}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.68275 20.5048C7.32133 20.8445 7.33495 21.4461 7.71318 21.8485C8.09142 22.251 8.69103 22.3019 9.05245 21.9622L14.9562 16.4135L20.5048 22.3173C20.8445 22.6787 21.4461 22.6651 21.8486 22.2868C22.251 21.9086 22.3019 21.309 21.9622 20.9476L16.4136 15.0438L22.3173 9.49527C22.6787 9.15559 22.6651 8.55398 22.2869 8.15154C21.9086 7.7491 21.309 7.69822 20.9476 8.03791L15.0439 13.5865L9.49531 7.68272C9.15563 7.3213 8.55402 7.33492 8.15158 7.71315C7.74913 8.09139 7.69826 8.691 8.03795 9.05242L13.5865 14.9562L7.68275 20.5048Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  );
};
