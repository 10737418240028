import { Agency } from 'model/UserInfo.model';

const appUrl = process.env.REACT_APP_API_URI_BASE;

export const getAgency = (
  agencyId: string,
  token?: string,
): Promise<Agency> => {
  return fetch(`${appUrl}/authentication/oav/user/agency/${agencyId}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then(_ => _.json());
};
