import CloseIcon from '@mui/icons-material/Close';
import Chip from '@mui/material/Chip';
import { Theme } from '@mui/material/styles/createTheme';

interface FilterOption {
  label: string;
}

const FilterChip = (props: {
  item: FilterOption;
  onDelete: () => void;
  theme: Theme;
}) => {
  return (
    <Chip
      label={props.item.label}
      onDelete={props.onDelete}
      color="default"
      variant="filled"
      sx={{
        backgroundColor: props.theme.palette.primary.contrastText,
        color: props.theme.palette.background.default,
      }}
      size="medium"
      deleteIcon={
        <CloseIcon
          sx={{
            '&.MuiChip-deleteIcon': {
              color: props.theme.palette.background.default,
            },
            '&.MuiChip-deleteIcon:hover': {
              color: 'inherit',
            },
          }}
        />
      }
    />
  );
};

export default FilterChip;
