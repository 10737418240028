import { Icon } from '.';
import { IconProps } from './settings';

export const SearchIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 30 30"
        fill={props.color}
      >
        <circle
          cx={13.593}
          cy={12.593}
          r={10.593}
          stroke={props.color}
          strokeWidth={2}
          fill="none"
        />
        <path
          stroke={props.color}
          strokeLinecap="round"
          strokeWidth={2}
          d="M21.296 21.26 27.074 28"
        />
      </svg>
    </Icon>
  );
};
