import { queryOptions } from '@tanstack/react-query';

import { useAccessToken } from 'utils/api/api';
import { PageResponse, Pagination } from 'models/Page.models';
import { Offer, OfferEligibility } from 'models/referentiels/Offer.model';
import {
  fetchOfferByCode,
  fetchOfferByCodeEligibility,
  fetchOfferDocuments,
  fetchOffers,
  fetchOffersEligibility,
  fetchOfferWarranties,
  OfferFilters,
} from 'api/referentiels/Offer.api';

export const offerQueries = {
  getAll: (filters?: OfferFilters, sort?: string[]) =>
    offerGetAllOptions(filters, sort),
  getPage: (pagination?: Pagination, filters?: OfferFilters, sort?: string[]) =>
    offerGetPageOptions(pagination, filters, sort),
  getPageEligibility: (
    context: OfferEligibility,
    pagination?: Pagination,
    filters?: OfferFilters,
    sort?: string[],
  ) => offerGetPageEligibilityOptions(context, pagination, filters, sort),
  getByCode: (code: string) => offerGetByCodeOptions(code),
  getByCodeEligibility: (code: string, context: OfferEligibility) =>
    offerGetByCodeEligibilityOptions(code, context),
  getByCodeDocuments: (code: string) => offerGetByCodeDocumentsOptions(code),
  getByCodeWarranties: (code: string) => offerGetByCodeWarrantiesOptions(code),
};

export const offerGetPageOptions = (
  pagination?: Pagination,
  filters?: OfferFilters,
  sort?: string[],
) => {
  const token = useAccessToken();

  return queryOptions({
    queryKey: ['offers', pagination, filters, sort],
    queryFn: () => {
      return fetchOffers(token, pagination, filters, sort);
    },
  });
};

export const offerGetAllOptions = (filters?: OfferFilters, sort?: string[]) => {
  const query = offerGetPageOptions(undefined, filters, sort);
  return {
    ...query,
    select: (data: PageResponse<Offer>) => data.content,
  };
};

export const offerGetPageEligibilityOptions = (
  context: OfferEligibility,
  pagination?: Pagination,
  filters?: OfferFilters,
  sort?: string[],
) => {
  const token = useAccessToken();

  return queryOptions({
    queryKey: ['offers', 'eligibility', context, pagination, filters, sort],
    queryFn: () => {
      return fetchOffersEligibility(token, context, pagination, filters, sort);
    },
  });
};

export const offerGetByCodeOptions = (code: string) => {
  const token = useAccessToken();

  return queryOptions({
    queryKey: ['offers', code],
    queryFn: () => {
      return fetchOfferByCode(token, code);
    },
  });
};

export const offerGetByCodeEligibilityOptions = (
  code: string,
  context: OfferEligibility,
) => {
  const token = useAccessToken();

  return queryOptions({
    queryKey: ['offers', code, 'eligibility', context],
    queryFn: () => {
      return fetchOfferByCodeEligibility(token, code, context);
    },
  });
};

export const offerGetByCodeDocumentsOptions = (code: string) => {
  const token = useAccessToken();

  return queryOptions({
    queryKey: ['offers', code, 'documents'],
    queryFn: () => {
      return fetchOfferDocuments(token, code);
    },
  });
};

export const offerGetByCodeWarrantiesOptions = (code: string) => {
  const token = useAccessToken();

  return queryOptions({
    queryKey: ['offers', code, 'warranties'],
    queryFn: () => {
      return fetchOfferWarranties(token, code);
    },
    select: data => data.sort((a, b) => a.order - b.order),
    staleTime: Infinity,
    gcTime: Infinity,
  });
};
