import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export type CardErrorProps = {
  status?: number;
};

const CardError = (props: CardErrorProps) => {
  return (
    <Card
      sx={{
        p: 2,
        height: '100%',
      }}
    >
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={1}
        sx={{
          height: '100%',
          textAlign: 'center',
        }}
      >
        {props.status === 404 ? (
          <>
            <Typography variant="h3">🤷‍♀️ Zut !</Typography>
            <Typography>
              Il semblerait que la ressource que vous cherchez n'existe pas.
            </Typography>
            <Typography variant="caption">
              Cette ressource a surement été supprimée ou désactivée.
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h3">❌ Oups !</Typography>
            <Typography>Une erreur est survenue.</Typography>
            <Typography variant="caption">
              Merci de réessayer ultérieurement.
            </Typography>
          </>
        )}
      </Stack>
    </Card>
  );
};

export default CardError;
