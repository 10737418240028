import React from 'react';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import { CountryData } from 'react-phone-input-material-ui';

import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';

import { FormClientsInfoValues } from 'pages/project/{uuid}/ProjectClientsInformationPage';
import { Beneficiary } from 'models/oav/Beneficiary.models';
import TextFieldPhone, {
  countryCodeEnabled,
} from 'components/TextField/TextFieldPhone/TextFieldPhone';
import TextField from 'components/TextField/TextField';
import { FieldReadOnly } from 'components/FieldReadOnly/FieldReadOnly';
import { civilityLabels } from 'models/oav/Person.models';
import { getRegime } from 'utils/fields/fields';

interface FormSubscriberClientInfoProps {
  subscriber: Beneficiary;
}

export const FormSubscriberClientInfo = (
  props: FormSubscriberClientInfoProps,
) => {
  const { values, errors, touched, setFieldValue, handleBlur, handleChange } =
    useFormikContext<FormClientsInfoValues>();

  const regime = getRegime(props.subscriber.person.regimeCode);

  return (
    <Grid
      container
      spacing={{
        sm: 2,
        xs: 1,
      }}
    >
      <Grid item xs={12}>
        <FieldReadOnly
          label="Civilité"
          value={
            props.subscriber.person.civility &&
            civilityLabels[props.subscriber.person.civility].label
          }
        />
      </Grid>
      <Grid item xs={6}>
        <FieldReadOnly
          label="Prénom"
          value={props.subscriber.person.firstname}
        />
      </Grid>
      <Grid item xs={6}>
        <FieldReadOnly label="Nom" value={props.subscriber.person.lastname} />
      </Grid>
      <Grid item xs={6}>
        <FieldReadOnly
          label="Date de naissance"
          value={dayjs(props.subscriber.person.birthdate).format('DD/MM/YYYY')}
        />
      </Grid>
      <Grid item xs={6}>
        <FieldReadOnly label="Régime social" value={regime?.label || ''} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextFieldPhone
          label="Téléphone Mobile"
          value={values.subscriber.phone?.number}
          name="subscriber.phone"
          onChange={(value, country) => {
            setFieldValue('subscriber.phone', {
              number: value,
              countryCode: (country as CountryData)?.dialCode,
            });
          }}
          error={touched.subscriber?.phone && Boolean(errors.subscriber?.phone)}
          regions="europe"
          country="fr"
          preferredCountries={['fr']}
          onlyCountries={countryCodeEnabled}
          countryCodeEditable={false}
          onBlur={handleBlur}
        />
        {touched.subscriber?.phone && errors.subscriber?.phone && (
          <FormHelperText error>{errors.subscriber?.phone}</FormHelperText>
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextFieldPhone
          label="Téléphone Fixe"
          value={values.subscriber.landline?.number}
          name="subscriber.landline"
          onChange={(value, country) => {
            setFieldValue('subscriber.landline', {
              number: value,
              countryCode: (country as CountryData)?.dialCode,
            });
          }}
          error={
            touched.subscriber?.landline && Boolean(errors.subscriber?.landline)
          }
          regions="europe"
          country="fr"
          preferredCountries={['fr']}
          onlyCountries={countryCodeEnabled}
          countryCodeEditable={false}
          onBlur={handleBlur}
        />
        {touched.subscriber?.landline && errors.subscriber?.landline && (
          <FormHelperText error>{errors.subscriber?.landline}</FormHelperText>
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          id="email"
          name="subscriber.email"
          label="Email"
          value={values.subscriber.email}
          error={touched.subscriber?.email && Boolean(errors.subscriber?.email)}
          helperText={
            touched.subscriber?.email &&
            Boolean(errors.subscriber?.email) && <>{errors.subscriber?.email}</>
          }
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Grid>
    </Grid>
  );
};
