import React from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { alpha, Card, Grid } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { useTheme } from '@mui/material/styles';
import { appName } from 'App';
import AppBar from 'components/AppBar';
import { Icon } from 'components/Icon';
import { Document } from 'models/document.model';
import { fetchDocumentUrl } from 'api/referentiels/document.api';
import { WrongStatusError } from 'api/referentiels';
import ButtonLink from 'components/Button/ButtonLink';
import { ChevronRightIcon } from 'components/Icon/ChevronRightIcon';
import { imageMapping } from 'utils/offer/imageMapping';
import { useQuery } from '@tanstack/react-query';
import { offerQueries } from 'api/referentiels/OfferQuery.api';

const OfferPage = () => {
  document.title = `Offre - ${appName}`;

  const auth = useAuth();
  const navigate = useNavigate();

  const accessToken = auth.user?.access_token;

  const theme = useTheme();
  const { code } = useParams();

  if (!code) {
    navigate('/offres');
    return null;
  }

  const { data: offer, error: offerError } = useQuery(
    offerQueries.getByCode(code),
  );
  const { data: documents } = useQuery(offerQueries.getByCodeDocuments(code));

  // Redirect to /offer if offer not found
  if ((offerError as WrongStatusError)?.status === 404) {
    navigate('/offres');
    return null;
  }

  // If error found redirect to home
  if (offerError) {
    navigate('/');
  }

  if (!offer) {
    return null;
  }

  const handleDownload = (document: Document) => async () => {
    if (accessToken) {
      try {
        const url = await fetchDocumentUrl(document.id, accessToken);
        window.open(url);
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <>
      <AppBar>
        <Box
          sx={{
            width: '100%',
            paddingBottom: 2,
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            flexWrap="wrap"
            useFlexGap
            spacing={1}
            sx={{
              width: '100%',
            }}
          >
            <Typography variant="h2">{offer.label}</Typography>
            <ButtonLink
              to="/offres"
              label="Aller à la bibliothèque"
              minified={false}
              icon={
                <ChevronRightIcon
                  size="small"
                  color={theme.palette.text.disabled}
                  style={{ transform: 'rotate(180deg)' }}
                />
              }
            />
          </Stack>
          <Stack
            direction="row"
            spacing={6}
            flexWrap="wrap"
            useFlexGap
            sx={{
              width: '100%',
              mt: 5,
            }}
          >
            {imageMapping[offer.code] && (
              <img
                style={{ height: 200, borderRadius: '24px' }}
                src={imageMapping[offer.code]}
                alt={`${offer.label} image.`}
              />
            )}
            <Typography
              variant="body1"
              sx={{
                width: '500px',
              }}
            >
              {offer.description}
            </Typography>
          </Stack>
          <Typography
            variant="h3"
            sx={{
              textDecoration: 'underline',
              my: 4,
            }}
          >
            Documents
          </Typography>
          <Grid container spacing={2}>
            {documents
              ?.sort((a, b) => a.type.label.localeCompare(b.type.label))
              .map(document => (
                <Grid key={offer.id} item xs={12} sm={4} md={3}>
                  <Card
                    sx={{
                      backgroundColor: theme.palette.background.default,
                      borderRadius: 8,
                      py: 0,
                      height: '100%',
                      cursor: 'pointer',
                      ':hover': {
                        border: `1px solid ${theme.palette.primary.main}`,
                        'box-shadow': `0px 0px 48.1px 0px ${alpha(theme.palette.primary.main, 0.5)}`,
                      },
                      display: 'flex',
                      flexDirection: 'row',
                      border: `1px solid ${theme.palette.divider}`,
                    }}
                    onClick={handleDownload(document)}
                  >
                    <Stack
                      justifyContent="space-between"
                      direction="row"
                      alignItems="center"
                      sx={{ p: 2, width: '100%' }}
                    >
                      <Typography
                        gutterBottom
                        variant="body1"
                        sx={{
                          mb: 0,
                        }}
                      >
                        {document.type.label}
                      </Typography>
                      <Icon fill={theme.palette.background.default}>
                        <DownloadIcon />
                      </Icon>
                    </Stack>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </Box>
      </AppBar>
    </>
  );
};

export default OfferPage;
