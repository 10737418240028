import React from 'react';
import { useFormikContext } from 'formik';
import Grid from '@mui/material/Grid';
import TextField from 'components/TextField/TextField';
import { AddressFrom } from 'content/form/AddressForm';
import { PersonFormValues } from 'models/Form.model';

const ContactInfoForm = () => {
  const { values, errors, touched, handleChange, handleBlur } =
    useFormikContext<PersonFormValues>();

  return (
    <>
      <AddressFrom />
      <Grid item xs={6}>
        <TextField
          fullWidth
          id="phone"
          name="phone"
          label="Téléphone mobile"
          value={values.phone}
          error={touched.phone && !!errors.phone}
          helperText={touched.phone && errors.phone}
          onChange={handleChange}
          onBlur={handleBlur}
          inputProps={{ minLength: 1, maxLength: 100 }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          id="landline"
          name="landline"
          label="Téléphone fixe"
          value={values.landline}
          error={touched.landline && !!errors.landline}
          helperText={touched.landline && errors.landline}
          onChange={handleChange}
          onBlur={handleBlur}
          inputProps={{ minLength: 1, maxLength: 100 }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          id="email"
          name="email"
          label="E-mail"
          value={values.email}
          error={touched.email && !!errors.email}
          helperText={touched.email && errors.email}
          onChange={handleChange}
          onBlur={handleBlur}
          inputProps={{ minLength: 1, maxLength: 100 }}
        />
      </Grid>
    </>
  );
};

export default ContactInfoForm;
