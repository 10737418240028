import { useMutation, useQueryClient } from '@tanstack/react-query';

import { RestError } from 'errors/RestError';
import {
  Project,
  ProjectStepAddressDetails,
  ProjectStepClientsInfo,
  ProjectStepContact,
  ProjectStepNeedsRequest,
  ProjectStepProposal,
  ProjectStepSubscriptions,
} from 'models/oav/Project.models';
import { useAccessToken } from 'utils/api/api';

const baseUrl = `${process.env.REACT_APP_API_URI_BASE}/oav/project`;

export const updateProjectStepSubscriptions = async (
  accessToken: string,
  id: string,
  payload: ProjectStepSubscriptions,
): Promise<Project> => {
  const url = `${baseUrl}/${id}/step/subscriptions`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'PUT',
      JSON.stringify(payload),
      await response.json(),
    );

  return await response.json();
};

export const updateProjectStepContact = async (
  accessToken: string,
  id: string,
  payload: ProjectStepContact,
): Promise<Project> => {
  const url = `${baseUrl}/${id}/step/contact`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'PUT',
      JSON.stringify(payload),
      await response.json(),
    );

  return await response.json();
};

export const updateProjectStepClientsInfo = async (
  accessToken: string,
  id: string,
  payload: ProjectStepClientsInfo,
): Promise<Project> => {
  const url = `${baseUrl}/${id}/step/clients-info`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'PUT',
      JSON.stringify(payload),
      await response.json(),
    );

  return await response.json();
};

export const useProjectStepClientsInfoMutation = (
  id: string,
  {
    onSuccess,
    onError,
  }: {
    onSuccess?: (project: Project) => void;
    onError?: (err: Error) => void;
  },
) => {
  const accessToken = useAccessToken();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: ProjectStepClientsInfo) =>
      updateProjectStepClientsInfo(accessToken, id, payload),
    onSuccess: (project: Project) => {
      queryClient.setQueryData(['projects', id], () => project);
      onSuccess?.(project);
    },
    onError: (error: Error) => {
      onError?.(error);
    },
  });
};

export const updateProjectStepNeeds = async (
  accessToken: string,
  id: string,
  payload: ProjectStepNeedsRequest,
): Promise<Project> => {
  const url = `${baseUrl}/${id}/step/needs`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'PUT',
      JSON.stringify(payload),
      await response.json(),
    );

  return await response.json();
};

export const useProjectStepNeedsMutation = (
  projectId: string,
  onSuccess?: (needs: ProjectStepNeedsRequest) => void,
  onError?: (error: Error) => void,
) => {
  const accessToken = useAccessToken();
  return useMutation({
    mutationFn: (params: { needs: ProjectStepNeedsRequest }) =>
      updateProjectStepNeeds(accessToken, projectId, params.needs),
    onSuccess: (needs: ProjectStepNeedsRequest) => {
      onSuccess?.(needs);
    },
    onError: (error: Error) => {
      onError?.(error);
    },
  });
};

export const updateProjectStepProposal = async (
  accessToken: string,
  id: string,
  payload: ProjectStepProposal,
): Promise<Project> => {
  const url = `${baseUrl}/${id}/step/proposal`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'PUT',
      JSON.stringify(payload),
      await response.json(),
    );

  return await response.json();
};

export const updateProjectStepAddressDetails = async (
  accessToken: string,
  id: string,
  payload: ProjectStepAddressDetails,
): Promise<Project> => {
  const url = `${baseUrl}/${id}/step/address-details`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'PUT',
      JSON.stringify(payload),
      await response.json(),
    );

  return await response.json();
};

export const useProjectStepAddressDetailsMutation = (
  id: string,
  {
    onSuccess,
    onError,
  }: {
    onSuccess?: (project: Project) => void;
    onError?: (err: Error) => void;
  },
) => {
  const accessToken = useAccessToken();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: ProjectStepAddressDetails) =>
      updateProjectStepAddressDetails(accessToken, id, payload),
    onSuccess: (project: Project) => {
      queryClient.setQueryData(['projects', id], () => project);
      onSuccess?.(project);
    },
    onError: (error: Error) => {
      onError?.(error);
    },
  });
};
