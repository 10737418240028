import { Icon } from '.';
import { IconProps } from './settings';

export const DashboardIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
          x="2"
          y="2"
          width="11"
          height="14"
          rx="1"
          stroke="currentColor"
          strokeWidth="2"
        />
        <rect
          x="2"
          y="20"
          width="11"
          height="8"
          rx="1"
          stroke="currentColor"
          strokeWidth="2"
        />
        <rect
          x="17"
          y="2"
          width="11"
          height="8"
          rx="1"
          stroke="currentColor"
          strokeWidth="2"
        />
        <rect
          x="17"
          y="14"
          width="11"
          height="14"
          rx="1"
          stroke="currentColor"
          strokeWidth="2"
        />
      </svg>
    </Icon>
  );
};
