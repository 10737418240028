import { Icon } from '.';
import { IconProps } from './settings';

export const LibraryIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" fill="none">
        <rect
          width={17}
          height={26}
          x={11}
          y={1}
          stroke={props.color}
          strokeWidth={2}
          rx={1}
        />
        <rect
          width={9}
          height={5}
          x={15}
          y={5}
          stroke={props.color}
          strokeWidth={2}
          rx={1}
        />
        <path
          stroke={props.color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M15 21h9M8.375 4.198l-2.35.63a1 1 0 0 0-.707 1.224l3.077 11.481M3.859 10.639l-1.455.84a1 1 0 0 0-.366 1.366L8.31 23.706M15 15h9"
        />
      </svg>
    </Icon>
  );
};
