import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

import { appName } from 'App';
import { ProjectStep } from 'models/oav/ProjectStep.models';
import { navigateToPreviousStep } from 'utils/project/project';
import { projectQueries } from 'api/oav/ProjectQuery.api';
import CardError from 'components/Card/CardError';
import { RestError } from 'errors/RestError';

const CURRENT_STEP = ProjectStep.FORMULA_CHOICE;

const ProjectFormulaChoicePage: React.FC = () => {
  document.title = `Projet - ${appName}`;

  const { id } = useParams();
  const navigate = useNavigate();

  if (!id) return <></>;

  const projectQuery = useQuery(projectQueries.getById(id));

  const isPending = false;

  if (projectQuery.error) {
    return (
      <Grid
        item
        xs={12}
        sx={{
          height: 200,
        }}
      >
        <CardError
          status={
            projectQuery.error instanceof RestError
              ? projectQuery.error.status
              : undefined
          }
        />
      </Grid>
    );
  }

  if (!projectQuery.data) {
    return (
      <Grid item xs={12}>
        <Skeleton variant="rectangular" animation="wave" height="200px" />
      </Grid>
    );
  }

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h3">Choix de la couverture</Typography>
      </Grid>

      <Grid item xs={12}></Grid>

      <Grid item sm="auto" xs={12}>
        <Button
          fullWidth
          color="default"
          disabled={isPending}
          onClick={() => navigateToPreviousStep(CURRENT_STEP, id, navigate)}
          sx={{ px: 4 }}
        >
          Retour
        </Button>
      </Grid>

      <Grid item sm="auto" xs={12}>
        <Button fullWidth type="submit" disabled={isPending} sx={{ px: 4 }}>
          {isPending ? (
            <CircularProgress color="inherit" size={28} />
          ) : (
            'Valider'
          )}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ProjectFormulaChoicePage;
