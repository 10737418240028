import { Icon } from '.';
import { IconProps } from './settings';

export const PersonCircleIllustrationIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <svg viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="20" cy="20" r="20" fill="url(#paint0_linear_158_714)" />
        <mask
          id="mask0_158_714"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="40"
          height="40"
        >
          <path
            d="M20 1.9868e-06C31.0457 1.50397e-06 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 -3.91405e-07 31.0457 -8.74228e-07 20C-1.35705e-06 8.95431 8.9543 2.46962e-06 20 1.9868e-06Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_158_714)">
          <path
            d="M1.43182 17.4427L-10.0964 28.6753C-10.8029 29.3637 -11.1245 30.3561 -10.956 31.3281L-9.22899 41.2917C-8.97886 42.7348 -7.72044 43.7844 -6.25589 43.7714L50.2641 43.2727C52.3997 43.2539 53.8278 41.0671 52.9866 39.1041L38.7188 5.81264C37.7281 3.50115 34.5003 3.37014 33.3256 5.59373L22.2668 26.5264C21.3813 28.2027 19.1794 28.6425 17.7178 27.4351L5.42359 17.279C4.24633 16.3065 2.5255 16.3771 1.43182 17.4427Z"
            fill="url(#paint1_linear_158_714)"
          />
        </g>
        <path
          d="M8 36.5801C8 29.6765 13.5964 24.0801 20.5 24.0801V24.0801C27.4036 24.0801 33 29.6765 33 36.5801V39.9999H8V36.5801Z"
          fill="currentColor"
        />
        <path
          d="M33 40.4999H33.5V39.9999V36.5801C33.5 29.4004 27.6797 23.5801 20.5 23.5801C13.3203 23.5801 7.5 29.4004 7.5 36.5801V39.9999V40.4999H8H33Z"
          stroke="currentColor"
        />
        <circle
          cx="20.5422"
          cy="15.4289"
          r="8.45991"
          fill="currentColor"
          stroke="currentColor"
        />
        <defs>
          <filter
            id="filter0_i_158_714"
            x="7"
            y="6.46899"
            width="28"
            height="34.531"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="1" />
            <feGaussianBlur stdDeviation="7.15" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.607843 0 0 0 0 0.623529 0 0 0 0 0.952941 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect1_innerShadow_158_714"
            />
          </filter>
          <linearGradient
            id="paint0_linear_158_714"
            x1="20"
            y1="0"
            x2="20"
            y2="40"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#94A7F4" />
            <stop offset="1" stop-color="#CE68E7" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_158_714"
            x1="22.9804"
            y1="4.91537"
            x2="22.9804"
            y2="43.7939"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" stopOpacity="0.6" />
            <stop offset="1" stop-color="white" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </Icon>
  );
};
