import { Icon } from '.';
import { IconProps } from './settings';

export const PersonCircleIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="15" cy="15" r="15" fill={props.bgColor} />
        <path
          d="M6 27.4346C6 22.2569 10.1973 18.0596 15.375 18.0596C20.5527 18.0596 24.75 22.2569 24.75 27.4346V29.9994H6V27.4346Z"
          fill="currentColor"
        />
        <ellipse
          cx="15.4067"
          cy="11.5715"
          rx="5.96993"
          ry="5.96993"
          fill="currentColor"
        />
      </svg>
    </Icon>
  );
};
