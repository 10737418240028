import { Icon } from '.';
import { IconProps } from './settings';

export const ChevronRightIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 23L20.1592 15.7474C20.6067 15.3496 20.6067 14.6504 20.1592 14.2526L12 7"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </Icon>
  );
};
