import React from 'react';
import { Grid } from '@mui/material';
import { FieldReadOnly } from 'components/FieldReadOnly/FieldReadOnly';
import { civilityLabels, genderLabels, Person } from 'models/oav/Person.models';
import { formatNni, getRegime } from 'utils/fields/fields';
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

interface IdentityViewTabProps {
  person: Person;
}

export const IdentityViewTab = (props: IdentityViewTabProps) => {
  const regime = getRegime(props.person.regimeCode);

  const regimeComponent = regime && (
    <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
      <Typography>{regime.label}</Typography>
    </Stack>
  );

  return (
    <>
      <Grid item xs={6}>
        <FieldReadOnly label="Prénom" value={props.person.firstname} />
      </Grid>
      <Grid item xs={6}>
        <FieldReadOnly label="Nom" value={props.person.lastname} />
      </Grid>
      <Grid item xs={6}>
        <FieldReadOnly
          label="Date de naissance"
          value={dayjs(props.person.birthdate).format('DD/MM/YYYY')}
        />
      </Grid>
      <Grid item xs={6}>
        <FieldReadOnly
          label="N° Sécurité sociale"
          value={formatNni(props.person.nni)}
        />
      </Grid>
      <Grid item xs={6}>
        <FieldReadOnly
          label="Civilité"
          value={
            props.person.civility && civilityLabels[props.person.civility].label
          }
        />
      </Grid>
      <Grid item xs={6}>
        <FieldReadOnly
          label="Genre"
          value={props.person.gender && genderLabels[props.person.gender].label}
        />
      </Grid>
      <Grid item xs={6}>
        <FieldReadOnly
          label="Rang de naissance"
          value={props.person.birthOrder?.toString()}
        />
      </Grid>
      <Grid item xs={6}>
        <FieldReadOnly label="Régime social" value={regimeComponent} />
      </Grid>
      <Grid item xs={6}>
        <FieldReadOnly
          label="N° Organisme d'affiliation"
          value={props.person.cpamId}
        />
      </Grid>
    </>
  );
};
