import { cloneElement, useMemo, useState } from 'react';

import { useTheme } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';

import WarrantySectionTableRows from './WarrantySectionTableRows';
import { ChevronBottomIcon } from 'components/Icon/ChevronBottomIcon';
import { NeedAssistanceIcon } from 'components/Icon/NeedAssistanceIcon';
import { NeedAudioIcon } from 'components/Icon/NeedAudioIcon';
import { NeedCureIcon } from 'components/Icon/NeedCureIcon';
import { NeedDentalIcon } from 'components/Icon/NeedDentalIcon';
import { NeedHospitalIcon } from 'components/Icon/NeedHospitalIcon';
import { NeedOpticalIcon } from 'components/Icon/NeedOpticalIcon';
import { NeedRoutineCareIcon } from 'components/Icon/NeedRoutineCareIcon';
import { NeedServiceIcon } from 'components/Icon/NeedServiceIcon';
import { NeedSoftMedicineIcon } from 'components/Icon/NeedSoftMedicineIcon';

import { Formula } from 'models/referentiels/Formula.model';
import {
  WarrantyAcronymMap,
  WarrantySection,
} from 'models/referentiels/Warranty.model';

export type WarrantySectionAccordionProps = {
  section: WarrantySection;
  formulas: Formula[];
  formulasSelected?: string[];
  onlyFormulas?: string[];
  opened?: boolean;
  onChange?: (opened: boolean) => void;
  acronymsMap?: WarrantyAcronymMap;
};

export const warrantySectionIcon: { [key: string]: { icon: JSX.Element } } = {
  Hospitalisation: { icon: <NeedHospitalIcon /> },
  'Soins courants': { icon: <NeedRoutineCareIcon /> },
  Dentaire: { icon: <NeedDentalIcon /> },
  Optique: { icon: <NeedOpticalIcon /> },
  'Aides Auditives': { icon: <NeedAudioIcon /> },
  Cures: { icon: <NeedCureIcon /> },
  "Prévention non prise en charge par l'Assurance Maladie": {
    icon: <NeedSoftMedicineIcon />,
  },
  'Assistance (FILASSISTANCE)': { icon: <NeedAssistanceIcon /> },
  'Service (MEDECIN DIRECT)': { icon: <NeedServiceIcon /> },
};

const WarrantySectionAccordion = (props: WarrantySectionAccordionProps) => {
  const theme = useTheme();

  const [opened, setOpened] = useState<boolean>(props.opened || false);

  const icon = useMemo(() => {
    const i = warrantySectionIcon[props.section.sectionTitle].icon;
    if (!i) return;
    return cloneElement(i, {
      size: 'medium',
      color: 'primary',
    });
  }, [props.section.sectionTitle]);

  return (
    <Accordion
      expanded={opened}
      onChange={(e, v) => setOpened(v)}
      sx={{
        borderRadius: 4,
        background: theme.palette.background.paper,
        '&.Mui-expanded': {
          border: 2,
          borderTop: 0,
          borderColor: theme.palette.divider,
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ChevronBottomIcon size="small" />}
        sx={{
          p: 1,
          borderRadius: 4,
          border: 2,
          borderColor: theme.palette.divider,
          backgroundColor: theme.palette.divider,
          '.MuiAccordionSummary-expandIconWrapper': {
            color: theme.palette.text.primary,
          },
        }}
      >
        <Stack direction="row" alignItems="center" gap={1}>
          {icon}
          <Typography>
            <b>{props.section.sectionTitle}</b>
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          p: 0,
          mb: 2,
        }}
      >
        <Table sx={{ width: '100%', overflow: 'hidden' }}>
          <TableBody sx={{ width: 'auto', overflow: 'hidden' }}>
            <WarrantySectionTableRows
              section={props.section}
              formulas={props.formulas}
              formulasSelected={props.formulasSelected}
              onlyFormulas={props.onlyFormulas}
              acronymsMap={props.acronymsMap}
            />
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  );
};

export default WarrantySectionAccordion;
