import { keepPreviousData, QueryClient } from '@tanstack/react-query';
import { UserManager, WebStorageStateStore } from 'oidc-client-ts';

export const userManager = new UserManager({
  authority: process.env.REACT_APP_KEYCLOAK_AUTHORITY || '',
  client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || '',
  client_secret: process.env.REACT_APP_KEYCLOAK_CLIENT_SECRET,
  redirect_uri: `${window.location.origin}${window.location.pathname}`,
  post_logout_redirect_uri: window.location.origin,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  monitorSession: true, // this allows cross tab login/logout detection
  stateStore: new WebStorageStateStore({
    store: localStorage,
  }),
});

export const onSigninCallback = () => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      placeholderData: keepPreviousData,
      retry: 0,
    },
  },
});
