import { Icon } from '.';
import { IconProps } from './settings';

export const CompanyIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <svg viewBox="0 0 18 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.75 3.25C0.75 1.59315 2.09315 0.25 3.75 0.25H15C16.6569 0.25 18 1.59315 18 3.25V25C18 26.6569 16.6569 28 15 28H11.25V23.125C11.25 22.0895 10.4105 21.25 9.375 21.25C8.33947 21.25 7.5 22.0895 7.5 23.125V28H3.75C2.09315 28 0.75 26.6569 0.75 25V3.25ZM2.25 9.5C2.25 8.94772 2.69772 8.5 3.25 8.5H5C5.55228 8.5 6 8.94772 6 9.5V12C6 12.5523 5.55228 13 5 13H3.25C2.69772 13 2.25 12.5523 2.25 12V9.5ZM3.25 2.5C2.69772 2.5 2.25 2.94772 2.25 3.5V6C2.25 6.55228 2.69772 7 3.25 7H5C5.55228 7 6 6.55228 6 6V3.5C6 2.94772 5.55228 2.5 5 2.5H3.25ZM2.25 15.5C2.25 14.9477 2.69772 14.5 3.25 14.5H5C5.55228 14.5 6 14.9477 6 15.5V18C6 18.5523 5.55228 19 5 19H3.25C2.69772 19 2.25 18.5523 2.25 18V15.5ZM8.5 8.5C7.94772 8.5 7.5 8.94772 7.5 9.5V12C7.5 12.5523 7.94772 13 8.5 13H10.25C10.8023 13 11.25 12.5523 11.25 12V9.5C11.25 8.94772 10.8023 8.5 10.25 8.5H8.5ZM7.5 3.5C7.5 2.94772 7.94772 2.5 8.5 2.5H10.25C10.8023 2.5 11.25 2.94772 11.25 3.5V6C11.25 6.55228 10.8023 7 10.25 7H8.5C7.94772 7 7.5 6.55228 7.5 6V3.5ZM13.75 8.5C13.1977 8.5 12.75 8.94772 12.75 9.5V12C12.75 12.5523 13.1977 13 13.75 13H15.5C16.0523 13 16.5 12.5523 16.5 12V9.5C16.5 8.94772 16.0523 8.5 15.5 8.5H13.75ZM12.75 3.5C12.75 2.94772 13.1977 2.5 13.75 2.5H15.5C16.0523 2.5 16.5 2.94772 16.5 3.5V6C16.5 6.55228 16.0523 7 15.5 7H13.75C13.1977 7 12.75 6.55228 12.75 6V3.5ZM8.5 14.5C7.94772 14.5 7.5 14.9477 7.5 15.5V18C7.5 18.5523 7.94772 19 8.5 19H10.25C10.8023 19 11.25 18.5523 11.25 18V15.5C11.25 14.9477 10.8023 14.5 10.25 14.5H8.5ZM12.75 15.5C12.75 14.9477 13.1977 14.5 13.75 14.5H15.5C16.0523 14.5 16.5 14.9477 16.5 15.5V18C16.5 18.5523 16.0523 19 15.5 19H13.75C13.1977 19 12.75 18.5523 12.75 18V15.5Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  );
};
