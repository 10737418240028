import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Regime } from 'models/referentiels/Regime.models';
import Select, { SelectProps } from 'components/Select/Select/Select';

import { useRegimes } from 'queries';

type RegimeSelectProps = Omit<SelectProps<string>, 'children'> & {
  touched?: boolean;
  error: boolean;
  helperText?: string | false | undefined;
};

export const RegimeSelect = (props: RegimeSelectProps) => {
  const { data: regimes, error: fetchError } = useRegimes();

  const dataPresent = !!regimes && regimes.length > 0;
  const emptyOrError = regimes?.length === 0 || fetchError;
  return (
    <>
      <Select<string>
        fullWidth
        required={props.required}
        id="regime"
        name="regime"
        label="Régime social"
        value={props.value}
        error={props.error}
        renderValue={value => {
          let r = { code: value } as Regime;

          if (Array.isArray(regimes))
            r = regimes?.find((_: Regime) => _?.code === value) || r;

          return (
            <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
              <Typography>{r?.label}</Typography>
            </Stack>
          );
        }}
        onChange={props.onChange}
        onBlur={props.onBlur}
      >
        {dataPresent
          ? regimes?.map((_: Regime) => {
              return (
                <MenuItem value={_.code} key={_.code}>
                  <Stack sx={{ width: '100%' }}>
                    <Typography>{_.label}</Typography>
                    {_.description && (
                      <Typography variant="caption">{_.description}</Typography>
                    )}
                  </Stack>
                </MenuItem>
              );
            })
          : emptyOrError && (
              <Stack
                justifyContent="center"
                spacing={1}
                sx={{
                  textAlign: 'center',
                  px: 1,
                }}
              >
                <Typography>❌ Oups !</Typography>
                <Typography variant="caption">
                  Une erreur est survenue lors de la récupération des régimes.
                </Typography>
              </Stack>
            )}
      </Select>
      {props.helperText && (
        <FormHelperText error>{props.helperText as string}</FormHelperText>
      )}
    </>
  );
};
