import { IconProps } from './settings';
import { Icon } from '.';

export const NeedAssistanceIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="path-1-inside-1_30_4503" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.0507 19.405C25.326 19.405 29.6025 15.1285 29.6025 9.85316C29.6025 4.5778 25.326 0.30127 20.0507 0.30127C14.7753 0.30127 10.4988 4.5778 10.4988 9.85316C10.4988 15.1285 14.7753 19.405 20.0507 19.405ZM20 20.2348C11.7157 20.2348 5 26.9505 5 35.2348V39.3386H35V35.2348C35 26.9505 28.2843 20.2348 20 20.2348Z"
          />
        </mask>
        <path
          d="M5 39.3386H3V41.3386H5V39.3386ZM35 39.3386V41.3386H37V39.3386H35ZM27.6025 9.85316C27.6025 14.024 24.2215 17.405 20.0507 17.405V21.405C26.4306 21.405 31.6025 16.2331 31.6025 9.85316H27.6025ZM20.0507 2.30127C24.2215 2.30127 27.6025 5.68236 27.6025 9.85316H31.6025C31.6025 3.47323 26.4306 -1.69873 20.0507 -1.69873V2.30127ZM12.4988 9.85316C12.4988 5.68237 15.8799 2.30127 20.0507 2.30127V-1.69873C13.6707 -1.69873 8.49877 3.47323 8.49877 9.85316H12.4988ZM20.0507 17.405C15.8799 17.405 12.4988 14.024 12.4988 9.85316H8.49877C8.49877 16.2331 13.6707 21.405 20.0507 21.405V17.405ZM7 35.2348C7 28.0551 12.8203 22.2348 20 22.2348V18.2348C10.6112 18.2348 3 25.846 3 35.2348H7ZM7 39.3386V35.2348H3V39.3386H7ZM35 37.3386H5V41.3386H35V37.3386ZM33 35.2348V39.3386H37V35.2348H33ZM20 22.2348C27.1797 22.2348 33 28.0551 33 35.2348H37C37 25.846 29.3888 18.2348 20 18.2348V22.2348Z"
          fill="currentColor"
          mask="url(#path-1-inside-1_30_4503)"
        />
      </svg>
    </Icon>
  );
};
