import { queryOptions } from '@tanstack/react-query';

import { useAccessToken } from 'utils/api/api';
import { fetchRegimeByCode, fetchRegimes } from './Regime.api';
import { RegimeFilters } from 'models/referentiels/Regime.models';

export const regimeQueries = {
  getAll: (filters?: RegimeFilters, sort?: string[]) =>
    regimeGetAllOptions(filters, sort),
  getByCode: (code: string) => regimeGetByCodeOptions(code),
};

export const regimeGetAllOptions = (
  filters?: RegimeFilters,
  sort?: string[],
) => {
  const token = useAccessToken();

  return queryOptions({
    queryKey: ['regimes', filters, sort],
    queryFn: () => fetchRegimes(token, filters, sort),
    staleTime: 300000,
    gcTime: 300000,
  });
};

export const regimeGetByCodeOptions = (code: string) => {
  const token = useAccessToken();

  return queryOptions({
    queryKey: ['regime', code],
    queryFn: () => fetchRegimeByCode(token, code),
    staleTime: 300000,
    gcTime: 300000,
  });
};
