import { RestError } from 'errors/RestError';

import { PageResponse } from 'models/api.model';
import { Pagination } from 'models/Page.models';
import { Project, ProjectRequestPost } from 'models/oav/Project.models';
import { buildParams } from 'utils/api/params';
import { Offer } from 'models/referentiels/Offer.model';

const baseUrl = `${process.env.REACT_APP_API_URI_BASE}/oav/project`;

// TODO
export interface FetchProjectsFilters {}

export const fetchProjects = async (
  accessToken: string,
  pagination?: Pagination,
  filters?: FetchProjectsFilters,
  sort?: string[],
): Promise<PageResponse<Project>> => {
  const params = new URLSearchParams();
  if (pagination)
    buildParams(pagination, 'pagination')?.forEach(([key, value]) =>
      params.append(key, value),
    );
  if (filters)
    buildParams(filters, 'filters')?.forEach(([key, value]) =>
      params.append(key, value),
    );
  if (sort) params.append('sort', sort.join(','));
  const url: string = params.size > 0 ? `${baseUrl}?${params}` : baseUrl;

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'GET',
      undefined,
      await response.json(),
    );

  return await response.json();
};

export const fetchProjectById = async (
  accessToken: string,
  id: string,
): Promise<Project> => {
  const url = `${baseUrl}/${id}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'GET',
      undefined,
      await response.json(),
    );

  return await response.json();
};

export const fetchProjectOffers = async (
  accessToken: string,
  id: string,
): Promise<PageResponse<Offer>> => {
  const url = `${baseUrl}/${id}/offers`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'GET',
      undefined,
      await response.json(),
    );

  return await response.json();
};

export const createProject = async (
  accessToken: string,
  payload: ProjectRequestPost,
): Promise<Project> => {
  const url = baseUrl;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'POST',
      JSON.stringify(payload),
      await response.json(),
    );

  return await response.json();
};
