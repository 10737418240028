import { Icon } from '.';
import { IconProps } from './settings';

export const PersonDuoCircleIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="15" cy="15" r="15" fill={props.bgColor} />
        <path
          d="M12.75 24.75C12.75 20.1937 16.4437 16.5 21 16.5C25.5563 16.5 29.25 20.1937 29.25 24.75V30H12.75V24.75Z"
          fill="currentColor"
        />
        <circle cx="20.8884" cy="11.1384" r="5.13842" fill="currentColor" />
        <path
          d="M0.75 26.25C0.75 22.5221 3.77208 19.5 7.5 19.5C11.2279 19.5 14.25 22.5221 14.25 26.25V30H0.75V26.25Z"
          fill="currentColor"
        />
        <circle cx="7.5" cy="14.25" r="4.5" fill="currentColor" />
      </svg>
    </Icon>
  );
};
