import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { regimeQueries } from 'api/referentiels/RegimeQuery.api';
import { Person } from 'models/oav/Person.models';

export type CardPersonProps = {
  person: Person;
  selected?: boolean;
  disabled?: boolean;
  onClick?: () => void;
};

const CardPerson = (props: CardPersonProps) => {
  const theme = useTheme();
  const regimesQuery = useQuery(regimeQueries.getAll());

  const regime = useMemo(() => {
    if (!regimesQuery.data) return undefined;
    return regimesQuery.data.find(r => r.code === props.person.regimeCode);
  }, [regimesQuery.data, props.person.regimeCode]);

  return (
    <Card
      sx={{
        px: 1,
        border: 2,
        borderColor: props.selected
          ? theme.palette.primary.main
          : theme.palette.divider,
        cursor: props.onClick && !props.disabled ? 'pointer' : 'default',
        ':hover': {
          backgroundColor: props.onClick
            ? alpha(theme.palette.divider, 0.8)
            : 'none',
        },
      }}
      onClick={() => {
        if (props.onClick) props.onClick();
      }}
    >
      <Stack direction="row" alignItems="center" gap={1}>
        <Checkbox
          size="small"
          checked={props.selected}
          disabled={props.disabled}
        />
        <Box
          flexGrow={1}
          sx={{
            py: 0.5,
          }}
        >
          <Typography variant="body2">
            {props.person.firstname && props.person.firstname + ' '}
            {props.person.lastname && props.person.lastname}
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            columnGap={1}
            divider={<span>-</span>}
            flexWrap="wrap"
            useFlexGap
          >
            <Typography variant="body1">
              <pre style={{ display: 'inline' }}>
                {dayjs(props.person.birthdate).format('DD/MM/YYYY')}
              </pre>
            </Typography>
            {regime && <Typography variant="body1">{regime.label}</Typography>}
          </Stack>
        </Box>
      </Stack>
    </Card>
  );
};

export default CardPerson;
