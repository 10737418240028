export class WrongStatusError extends Error {
  status: number;
  constructor(status: number, message: string) {
    super(message);
    this.status = status;
  }
}

export { fetchRisks } from './risks.api';
export { fetchSegments } from './segment.api';
