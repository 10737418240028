import { DocumentDownload } from '../../models/document.model';
import { WrongStatusError } from './index';

const url = `${process.env.REACT_APP_API_URI_BASE}/referentiels/document`;

const fetchDocumentUrl = async (
  documentId: string,
  accessToken: string,
): Promise<string> => {
  const response = await fetch(`${url}/${documentId}/download`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (response.status !== 200) {
    throw new WrongStatusError(response.status, 'Wrong http status.');
  }
  return ((await response.json()) as DocumentDownload).url;
};

export { fetchDocumentUrl };
