import { matchPath, useLocation } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';

import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';

import AvatarUser from '../Avatar/AvatarUser';
import ButtonLink from '../Button/ButtonLink';
import ButtonLinkUser from '../Button/ButtonLinkUser';
import { DashboardIcon } from '../Icon/DashboardIcon';
import { ProjectIcon } from '../Icon/ProjectIcon';
import { PersonIcon } from '../Icon/PersonIcon';
import { LibraryIcon } from '../Icon/LibraryIcon';
import { useUserContext } from '../Context/UserContext';
import { Disconnect } from '../Icon/Disconnect';

export type SidebarProps = {
  minified?: boolean;
};

const SidebarContent = (props: SidebarProps) => {
  const { pathname } = useLocation();
  const auth = useAuth();
  const theme = useTheme();
  const userContext = useUserContext();

  const navs = [
    {
      label: 'Tableau de bord',
      icon: <DashboardIcon />,
      to: 'dashboard',
    },
    {
      label: 'Projets',
      icon: <ProjectIcon />,
      to: 'projets',
      match: 'projets/*',
    },
    {
      label: 'Personnes',
      icon: <PersonIcon />,
      to: 'personnes',
      match: 'personnes/*',
    },
    {
      label: 'Bibliothèque',
      icon: <LibraryIcon />,
      to: 'offres',
      match: 'offres/*',
    },
  ];

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      alignItems={props.minified ? 'center' : 'stretch'}
      sx={{
        height: '100%',
      }}
    >
      <Stack direction="column" spacing={2}>
        {!props.minified && (
          <ButtonLinkUser
            user={userContext.data.user}
            agency={userContext.data.agency}
            to="compte"
            selected={matchPath('compte/*', pathname) !== null}
            minified={props.minified}
          />
        )}

        <Stack direction="column" spacing={props.minified ? 0.5 : 0}>
          {navs.map(_ => (
            <ButtonLink
              {..._}
              key={_.to}
              selected={matchPath(_.match ?? _.to, pathname) !== null}
              minified={props.minified}
            />
          ))}
        </Stack>
      </Stack>

      <Stack direction="column" spacing={1}>
        {props.minified && (
          <AvatarUser
            user={userContext.data.user}
            selected={matchPath('compte/*', pathname) !== null}
            minified={props.minified}
          />
        )}
        <ButtonLink
          label="Déconnexion"
          icon={<Disconnect />}
          iconPosition="end"
          color={theme.palette.error.main}
          onClick={() =>
            auth?.signoutRedirect({ id_token_hint: auth.user?.id_token })
          }
          minified={props.minified}
        />
      </Stack>
    </Stack>
  );
};

export default SidebarContent;
