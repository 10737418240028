import React, { ReactElement } from 'react';

import Box from '@mui/material/Box';
import { Card, Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Divider from '@mui/material/Divider';

import ButtonLink from 'components/Button/ButtonLink';
import { PersonIcon } from 'components/Icon/PersonIcon';
import {
  PersonTabKeys,
  usePersonContext,
} from 'components/Context/PersonContext';

interface PersonTabProps {
  children: ReactElement | ReactElement[];
  tab: PersonTabKeys;
}

export const PersonTab = (props: PersonTabProps) => <>{props.children}</>;

interface PersonTabsProps {
  children: ReactElement<PersonTabProps>[];
  gap?: 'medium' | 'large';
}

export const PersonTabs = (props: PersonTabsProps) => {
  const { gap = 'medium' } = props;
  const { selectedTab, setSelectedTab } = usePersonContext();
  const theme = useTheme();
  const screenSizeUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  const children = props.children.find(c => c.props.tab === selectedTab);

  return (
    <Box display="flex" justifyContent="center" sx={{ pt: 4, width: '100%' }}>
      <Card
        variant="outlined"
        sx={{
          p: 2,
          backgroundColor: 'transparent',
          width: '100%',
          maxWidth: 1200,
        }}
      >
        <Stack
          direction="row"
          justifyContent="center"
          spacing={{ lg: 2, xs: 1 }}
          divider={
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                display: {
                  xs: 'none',
                  lg: 'flex',
                },
              }}
            />
          }
        >
          <ButtonLink
            label="Identité"
            icon={<PersonIcon />}
            key="identity"
            selected={selectedTab === 'identity'}
            minified={!screenSizeUpLg}
            onClick={() => setSelectedTab('identity')}
            size="medium"
          />
          <ButtonLink
            label="Coordonnées"
            icon={<PlaceOutlinedIcon sx={{ width: 30, height: 30 }} />}
            key="contact-info"
            selected={selectedTab === 'contact-info'}
            onClick={() => setSelectedTab('contact-info')}
            minified={!screenSizeUpLg}
            size="medium"
          />
        </Stack>
        <Box sx={{ p: 2, mt: 3 }}>
          <Grid
            container
            spacing={{
              sm: gap === 'medium' ? 2 : 4,
              xs: gap === 'medium' ? 1 : 2,
            }}
          >
            {children}
          </Grid>
        </Box>
      </Card>
    </Box>
  );
};
