import { matchPath } from 'react-router-dom';
import {
  SubscriptionRequest,
  SubscriptionRequestErrors,
} from './Subscription.models';

export enum ProjectStep {
  'SUBSCRIPTIONS' = 'SUBSCRIPTIONS',
  'CONTACT' = 'CONTACT',
  'NEEDS' = 'NEEDS',
  'PROPOSAL' = 'PROPOSAL',
  'BENEFICIARIES' = 'BENEFICIARIES',
  'CLIENTS_INFO' = 'CLIENTS_INFO',
  'ADDRESS_DETAILS' = 'ADDRESS_DETAILS',
  'FORMULA_CHOICE' = 'FORMULA_CHOICE',
}

export type ProjectStepData = {
  order: number;
  label: string;
  path: string;
  pathMatch: string;
};

export const projectStepsData: Partial<Record<ProjectStep, ProjectStepData>> = {
  [ProjectStep.SUBSCRIPTIONS]: {
    order: 0,
    label: 'Offres',
    path: 'offres',
    pathMatch: 'projets/:id/offres',
  },
  [ProjectStep.CONTACT]: {
    order: 1,
    label: 'Contact',
    path: 'contact',
    pathMatch: 'projets/:id/contact',
  },
  [ProjectStep.NEEDS]: {
    order: 2,
    label: 'Besoins',
    path: 'besoins',
    pathMatch: 'projets/:id/besoins',
  },
  [ProjectStep.PROPOSAL]: {
    order: 3,
    label: 'Proposition',
    path: 'proposition',
    pathMatch: 'projets/:id/proposition',
  },
  [ProjectStep.CLIENTS_INFO]: {
    order: 4,
    label: 'Données clients',
    path: 'donnees-clients',
    pathMatch: 'projets/:id/donnees-clients',
  },
  [ProjectStep.ADDRESS_DETAILS]: {
    order: 5,
    label: 'Coordonnées postales',
    path: 'coordonnees-postales',
    pathMatch: 'projets/:id/coordonnees-postales',
  },
  [ProjectStep.FORMULA_CHOICE]: {
    order: 6,
    label: 'Choix de la couverture',
    path: 'choix-de-la-couverture',
    pathMatch: 'projets/:id/choix-de-la-couverture',
  },
};

export const getFirstStep = (): ProjectStep => {
  const entries = Object.entries(projectStepsData) as [
    ProjectStep,
    ProjectStepData,
  ][];

  const nextSteps = entries.sort(
    ([, dataA], [, dataB]) => dataA.order - dataB.order,
  );

  return nextSteps[0][0];
};

export const getNextStep = (current: ProjectStep): ProjectStep | undefined => {
  const currentData = projectStepsData[current];
  if (!currentData) return;

  const entries = Object.entries(projectStepsData) as [
    ProjectStep,
    ProjectStepData,
  ][];

  const nextSteps = entries
    .filter(([key, data]) => data.order > currentData.order)
    .sort(([, dataA], [, dataB]) => dataA.order - dataB.order);

  return nextSteps.length > 0 ? nextSteps[0][0] : undefined;
};

export const getPreviousStep = (
  current: ProjectStep,
): ProjectStep | undefined => {
  const currentData = projectStepsData[current];
  if (!currentData) return;

  const entries = Object.entries(projectStepsData) as [
    ProjectStep,
    ProjectStepData,
  ][];

  const prevSteps = entries
    .filter(([key, data]) => data.order < currentData.order)
    .sort(([, dataA], [, dataB]) => dataB.order - dataA.order);

  return prevSteps.length > 0 ? prevSteps[0][0] : undefined;
};

export const getStepFromPath = (path: string): ProjectStep | undefined => {
  const entries = Object.entries(projectStepsData) as [
    ProjectStep,
    ProjectStepData,
  ][];
  const step = entries.find(
    ([step, data]) => matchPath(data.pathMatch, path) !== null,
  );

  return step ? step[0] : undefined;
};

export const isStepDone = (
  step: ProjectStep,
  current?: ProjectStep,
): boolean => {
  if (!current) return true;
  return projectStepsData[step]!.order < projectStepsData[current]!.order;
};

export const isStepDisabled = (
  step: ProjectStep,
  current?: ProjectStep,
): boolean => {
  if (!current) return true;
  return projectStepsData[step]!.order > projectStepsData[current]!.order;
};

export type ProjectStepSubscriptions = {
  subscriptions: SubscriptionRequest[];
};

export type ProjectStepSubscriptionsErrors = {
  subscriptions?: string;
  [key: string]: SubscriptionRequestErrors | string | undefined;
};
