import { memo, useMemo } from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { ChipWarrantyAcronym } from 'components/Chip/ChipWarrantyAcronym';
import { CheckCircleFilledIcon } from 'components/Icon/CheckCircleFilledIcon';
import { CloseIcon } from 'components/Icon/CloseIcon';

import {
  WarrantyAcronymMap,
  WarrantyAmount,
  warrantyAmountTypeLabel,
} from 'models/referentiels/Warranty.model';

export type WarrantyFormulaAmountsProps = {
  formulaWarrantyAmounts?: WarrantyAmount[];
  acronymsMap?: WarrantyAcronymMap;
};

const WarrantyFormulaAmounts = memo((props: WarrantyFormulaAmountsProps) => {
  const formulaWarrantyAmounts = useMemo(
    () => props.formulaWarrantyAmounts?.sort((a, b) => a.order - b.order),
    [props.formulaWarrantyAmounts],
  );
  return (
    <Stack direction="row" justifyContent="center" gap={1} flexWrap="wrap">
      {formulaWarrantyAmounts ? (
        formulaWarrantyAmounts.map((amount, idx) => {
          const acronym =
            amount.amountAcronym &&
            props.acronymsMap &&
            props.acronymsMap[amount.amountAcronym];
          const addDivider =
            idx !== 0 &&
            props.formulaWarrantyAmounts &&
            idx < props.formulaWarrantyAmounts?.length;

          return (
            <Stack direction="row" gap={1} key={idx}>
              {addDivider && <>+</>}

              {amount.amount && (
                <pre
                  style={{
                    display: 'inline-flex',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {amount.amount}
                  {warrantyAmountTypeLabel[amount.amountType]?.label}
                </pre>
              )}

              {acronym && <ChipWarrantyAcronym acronym={acronym} />}

              {amount.description && amount.description === 'OUI' ? (
                <CheckCircleFilledIcon size="small" color="success" />
              ) : amount.description === 'NON' ? (
                <CloseIcon size="small" color="error" />
              ) : (
                <Typography>{amount.description}</Typography>
              )}
            </Stack>
          );
        })
      ) : (
        <b>-</b>
      )}
    </Stack>
  );
});

WarrantyFormulaAmounts.displayName = 'WarrantyFormulaAmounts';

export default WarrantyFormulaAmounts;
