import Stack from '@mui/material/Stack';
import ButtonLink, { ButtonLinkProps } from '../../Button/ButtonLink';
import Divider from '@mui/material/Divider';
import { matchPath, useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export type TabMenuProps = {
  buttons: ButtonLinkProps[];
};

const TabMenu: React.FC<TabMenuProps> = (props: TabMenuProps) => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const screenSizeUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Stack
      direction="row"
      justifyContent="center"
      spacing={{ lg: 2, xs: 1 }}
      divider={
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            display: {
              xs: 'none',
              lg: 'flex',
            },
          }}
        />
      }
    >
      {props.buttons.map(button => (
        <ButtonLink
          {...button}
          key={button.label}
          selected={
            button.to !== undefined &&
            matchPath(button.to.toString(), pathname) !== null
          }
          minified={!screenSizeUpLg}
          size="medium"
        />
      ))}
    </Stack>
  );
};

export default TabMenu;
