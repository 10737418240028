import { ButtonLinkProps } from '../../components/Button/ButtonLink';
import { PersonIcon } from '../../components/Icon/PersonIcon';
import { AccountIcon } from '../../components/Icon/AccountIcon';
import { CompanyIcon } from '../../components/Icon/CompanyIcon';
import { ProtocolIcon } from '../../components/Icon/ProtocolIcon';

export const AccountMenu: ButtonLinkProps[] = [
  {
    label: 'Vos informations',
    icon: <PersonIcon />,
    to: '/compte',
  },
  {
    label: 'Votre compte',
    icon: <AccountIcon />,
    to: '/compte/utilisateur',
  },
  {
    label: 'Votre entreprise',
    icon: <CompanyIcon />,
    to: '/compte/entreprise',
  },
  {
    label: 'Votre protocole',
    icon: <ProtocolIcon />,
    to: '/compte/protocole',
  },
];
