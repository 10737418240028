import React from 'react';

import { Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import { Person } from 'models/oav/Person.models';
import { IdentityViewTab } from './IdentityViewTab';
import { ContactInfoViewTab } from './ContactInfoViewTab';
import { PersonTab, PersonTabs } from '../PersonTabs';

interface PersonViewProps {
  person: Person;
  onUpdate: () => void;
}

const Buttons = (props: { onUpdate: () => void }) => (
  <Grid item xs={12}>
    <Stack
      direction="row"
      alignItems="center"
      flexWrap="wrap"
      useFlexGap
      spacing={2}
      justifyContent="flex-end"
      sx={{ mt: 2 }}
    >
      <Button size="medium" onClick={props.onUpdate} sx={{ px: 2 }}>
        Mettre à jour
      </Button>
    </Stack>
  </Grid>
);

export const PersonView = (props: PersonViewProps) => (
  <PersonTabs>
    <PersonTab tab="identity">
      <IdentityViewTab person={props.person} />
      <Buttons onUpdate={props.onUpdate} />
    </PersonTab>
    <PersonTab tab="contact-info">
      <ContactInfoViewTab person={props.person} />
      <Buttons onUpdate={props.onUpdate} />
    </PersonTab>
  </PersonTabs>
);
