import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';

import {
  PersonContext,
  PersonContextData,
  PersonTabKeys,
} from 'components/Context/PersonContext';
import { Pagination } from 'models/Page.models';

export const LayoutPerson = () => {
  const [selectedTab, setSelectedTab] = useState<PersonTabKeys>('identity');
  const [pagination, setPagination] = useState<Pagination>({
    page: 0,
    size: 10,
  });

  const [searchCriteria, setSearchCriteria] = useState<
    PersonContextData['searchCriteria']
  >({
    value: {
      lastname: '',
      birthdate: null,
      firstname: '',
      email: '',
    },
    errors: {},
  });

  return (
    <PersonContext.Provider
      value={{
        selectedTab,
        setSelectedTab,
        pagination,
        setPagination,
        searchCriteria,
        setSearchCriteria,
      }}
    >
      <Outlet />
    </PersonContext.Provider>
  );
};
