import React from 'react';

import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';

interface ErrorMessageProps {
  message: string;
}
export const ErrorMessage = (props: ErrorMessageProps) => (
  <Card
    sx={{
      p: 2,
      height: 200,
    }}
  >
    <Stack
      alignItems="center"
      justifyContent="center"
      spacing={1}
      sx={{
        height: '100%',
        textAlign: 'center',
      }}
    >
      <Typography variant="h3">❌ Oups !</Typography>
      <Typography>{props.message}</Typography>
      <Typography variant="caption">
        Merci de réessayer ultérieurement.
      </Typography>
    </Stack>
  </Card>
);
