import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import Stack from '@mui/material/Stack';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { Link as LinkMUI } from '@mui/material';

import AppBar from 'components/AppBar';
import { appName } from 'App';
import { ChevronRightIcon } from 'components/Icon/ChevronRightIcon';
import { usePerson } from 'queries';
import { WrongStatusError } from 'api/referentiels';
import { PersonView } from './PersonView';
import { PersonUpdate } from './PersonUpdate';

const PersonPage: React.FC = () => {
  document.title = `Personnes - ${appName}`;

  const navigate = useNavigate();
  const { uuid } = useParams();
  const [mode, setMode] = useState<'view' | 'update'>('view');

  if (!uuid) {
    navigate('/personnes');
    return null;
  }

  const { data: person, error } = usePerson(uuid);

  // Redirect to /personnes if person not found
  if ((error as WrongStatusError)?.status === 404) {
    navigate('/personnes');
    return null;
  }

  // If error found redirect to home
  if (error) {
    return <p>Oups</p>;
  }

  if (!person) {
    return null;
  }

  return (
    <>
      <AppBar>
        <Stack
          direction="row"
          justifyContent="space-between"
          flexWrap="wrap"
          useFlexGap
          spacing={1}
          sx={{
            width: '100%',
          }}
        >
          <Breadcrumbs separator={<ChevronRightIcon size="small" />}>
            <Link to="/personnes" style={{ color: 'inherit' }}>
              <LinkMUI underline="hover" variant="body2" color="inherit">
                Personnes
              </LinkMUI>
            </Link>
            <Typography color="text.primary">
              {person.firstname} {person.lastname}
            </Typography>
          </Breadcrumbs>
        </Stack>
      </AppBar>
      {mode === 'view' && (
        <PersonView person={person} onUpdate={() => setMode('update')} />
      )}
      {mode === 'update' && (
        <PersonUpdate
          person={person}
          onBackToViewMode={() => setMode('view')}
        />
      )}
    </>
  );
};

export default PersonPage;
