import InputAdornment, {
  InputAdornmentProps,
} from '@mui/material/InputAdornment';

const DatePickerInputAdornment = (props: InputAdornmentProps) => {
  return (
    <InputAdornment
      {...props}
      sx={{
        mr: '4px',
      }}
    >
      {props.children}
    </InputAdornment>
  );
};

export default DatePickerInputAdornment;
